import { ActivityInstance } from '@api';
import { DialogContentText } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const ActivityInformation = ({
  activity,
  useParagraph = true,
}: {
  activity: ActivityInstance;
  useParagraph?: boolean;
}) => {
  const { t } = useTranslation();
  const getActivityStatus = (instance: ActivityInstance) => {
    if (instance) {
      const started = instance.dateIsStarted;
      const completed = instance.dateIsCompleted;

      if (completed) return t('Activity Completed');
      if (started) return t('Started');
    }

    return t('Not started');
  };

  const getTimeFormat = () => {
    if (activity.duration > 59) {
      const hours = Math.floor(activity.duration / 60);
      const minutes = activity.duration % 60;
      let hourText;
      if (hours == 1) hourText = t('hour');
      else hourText = t('hours');
      if (minutes == 0) return `${hours} ${hourText}`;

      return `${hours} ${hourText} ${minutes} ${t('minutes')}`;
    }

    return `${activity.duration} ${t('min')}`;
  };

  return (
    <>
      <DialogContentText paragraph={useParagraph}>
        {`${t('Date')}: `}
        {format(activity.startDate, 'MM/dd/yyyy')}
      </DialogContentText>
      <DialogContentText paragraph={useParagraph}>
        {`${t('Time')}: `}
        {format(activity.startDate, 'HH:mm')}
      </DialogContentText>
      <DialogContentText paragraph={useParagraph}>
        {t('Duration')}: {getTimeFormat()}
      </DialogContentText>
      <DialogContentText paragraph={useParagraph}>
        {`${t('Status')}: `}
        {getActivityStatus(activity)}
      </DialogContentText>
    </>
  );
};

export default ActivityInformation;
