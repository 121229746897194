import {
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useContext } from 'react';
import { AIProfileContext } from '@/contexts/AIProfileContext';
import { useTranslation } from 'react-i18next';

const BasicInfoForm = () => {
  const { aiProfile, setAIProfile } = useContext(AIProfileContext);
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography>{t('Age')}:</Typography>
        <TextField
          aria-label="Age input"
          value={aiProfile.age}
          onChange={(e) => {
            // Only accept ages between 0 and 122
            if (e.target.value == '00') return;
            if (e.target.value.includes('-')) {
              return;
            }
            const val = parseInt(e.target.value);
            if (val < 0 || val > 122) return;
            setAIProfile({ ...aiProfile, age: val });
          }}
          type="number"
          sx={{ width: '4em' }}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography>{t('Gender')}:</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Kön</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={aiProfile.gender}
            label={t('Age')}
            onChange={(e) => setAIProfile({ ...aiProfile, gender: e.target.value })}
          >
            <MenuItem value={'MALE'}>{t('Man')}</MenuItem>
            <MenuItem value={'FEMALE'}>{t('Woman')}</MenuItem>
            <MenuItem value={'OTHER'}>{t('Other gender')}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default BasicInfoForm;
