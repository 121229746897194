import { Board, Note, NoteApi, ResponseError } from '@api';
import { createContext, useContext, useEffect, useState } from 'react';
import { SupervisorContext } from './SupervisorContext';

type props = {
  children?: React.ReactNode;
};

export type NotesContextType = {
  boards: Board[];
  setBoards: (boards: Board[]) => void;
  fetchBoards: () => void;
  createBoard: (board: Board) => void;
  deleteBoard: (board: Board) => void;
  editBoard: (board: Board) => void;
  createNote: (note: Note) => Promise<ResponseError | undefined> | void;
  deleteNote: (noteId: number, noteBoardId: number) => void;
  editNote: (note: Note) => Promise<ResponseError | undefined> | void;
  updateNoteOrder: (notes: Note[], noteBoardId: number) => void;
  updateBoardOrder: (boards: Board[]) => void;
  loaded?: boolean;
};

const defaultContext: NotesContextType = {
  boards: [],
  setBoards: () => {},
  fetchBoards: () => {},
  createBoard: () => {},
  deleteBoard: () => {},
  editBoard: () => {},
  createNote: () => {},
  deleteNote: () => {},
  editNote: () => {},
  updateNoteOrder: () => {},
  updateBoardOrder: () => {},
  loaded: false,
};

export const NotesContext = createContext<NotesContextType>(defaultContext);

export const NotesScene = ({ children }: props) => {
  const [boards, setBoards] = useState<Board[]>([] as Board[]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const { currentClient, isSupervising } = useContext(SupervisorContext);

  const fetchBoards = async () => {
    const res = await new NoteApi().getBoards({
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) {
      const sortedBoards = res.sort((a, b) => a.position - b.position);
      sortedBoards.forEach((board) => {
        board.notes.sort((a, b) => a.position - b.position);
      });

      setBoards(sortedBoards);
    }
    setLoaded(true);
  };

  const createBoard = async (newBoard: Board) => {
    const res = await new NoteApi().createBoard({
      board: newBoard,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) {
      setBoards([...boards, res]);
    }
  };

  const deleteBoard = async (board: Board) => {
    const res = await new NoteApi().removeBoard({
      boardId: board.id,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) {
      setBoards([...boards.filter((noteBoard) => noteBoard.id !== board.id)]);
    }
  };

  const editBoard = async (board: Board) => {
    const res = await new NoteApi().editBoard({
      board: board,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res === 200) {
      setBoards([...boards.map((b) => (b.id === board.id ? board : b))]);
    }
  };

  const createNote = async (note: Note) => {
    const res = await new NoteApi().createNote({
      note: note,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res && res instanceof ResponseError) {
      return res;
    }
    if (res) {
      const boardIndex = boards.findIndex((board) => board.id === res.noteBoardId);
      if (boardIndex > -1) {
        boards[boardIndex].notes = [...boards[boardIndex].notes, res];
      }
    }
  };

  const deleteNote = async (noteId: number, noteBoardId: number) => {
    const res = await new NoteApi().removeNote({
      noteId: noteId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) {
      const boardIndex = boards.findIndex((board) => board.id === noteBoardId);
      if (boardIndex > -1) {
        boards[boardIndex].notes = [...boards[boardIndex].notes.filter((n) => n.id !== noteId)];
      }
    }
  };

  const editNote = async (note: Note) => {
    const res = await new NoteApi().editNote({
      note: note,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });

    if (res && res instanceof ResponseError) {
      return res;
    }
    if (res) {
      const boardIndex = boards.findIndex((board) => board.id === note.noteBoardId);
      if (boardIndex > -1)
        boards[boardIndex].notes = [
          ...boards[boardIndex].notes.map((n) => (n.id === note.id ? note : n)),
        ];
    }
  };

  const updateNoteOrder = async (notes: Note[], noteBoardId: number) => {
    const res = await new NoteApi().updateNotesPosition({
      notes: [...notes],
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) {
      const boardIndex = boards.findIndex((board) => board.id === noteBoardId);
      if (boardIndex > -1) boards[boardIndex].notes = [...notes];
    }
  };

  const updateBoardOrder = async (boards: Board[]) => {
    const res = await new NoteApi().updateBoardPosition({
      boards: [...boards],
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
  };

  useEffect(() => {
    if (isSupervising) {
      if (currentClient) fetchBoards();
    } else fetchBoards();
  }, [isSupervising, currentClient]);

  return (
    <NotesContext.Provider
      value={{
        boards,
        setBoards,
        fetchBoards,
        createBoard,
        deleteBoard,
        editBoard,
        createNote,
        deleteNote,
        editNote,
        updateNoteOrder,
        updateBoardOrder,
        loaded,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
