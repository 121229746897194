import { AiApi, ResponseError } from '@api';
import { HttpStatusCode } from 'axios';
import { createContext, useEffect, useState } from 'react';

type props = {
  children?: React.ReactNode;
};

export type AIProfile = {
  aiProfileId: number;
  age: number | undefined;
  gender: string | undefined;
  interests: string | undefined;
  preferredActivities: string[];
  generatedImages: number | undefined;
};

// TODO Check usage
export type AIForm = {
  component: () => JSX.Element;
  title: string;
};

type AIProfileContextType = {
  aiProfile: AIProfile;
  setAIProfile: (aiProfile: AIProfile) => void;
  saveAIProfile: () => Promise<AIProfile | ResponseError | undefined>;
  getAIProfile: () => void;
  editAIProfile: (aiProfile: AIProfile) => Promise<HttpStatusCode | ResponseError | undefined>;
  removeAIProfile: () => Promise<HttpStatusCode | ResponseError | undefined>;
};

export const AIProfileContext = createContext<AIProfileContextType>({} as AIProfileContextType);

//ToDo This component is rendered twice when setActivity is called, it should not do that
export const AIProfileScene = ({ children }: props) => {
  const [aiProfile, setAIProfile] = useState<AIProfile>({} as AIProfile);

  const saveAIProfile = async (): Promise<AIProfile | ResponseError | undefined> => {
    const res = await new AiApi().createAIProfile(aiProfile);
    return res;
  };

  const getAIProfile = async () => {
    const res = await new AiApi().getAIProfile();
    if (res) setAIProfile(res);
  };

  const editAIProfile = async (
    aiProfile: AIProfile,
  ): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new AiApi().updateAIProfile(aiProfile);
    return res;
  };

  const removeAIProfile = async (): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new AiApi().deleteAIProfile();
    return res;
  };

  useEffect(() => {
    getAIProfile();
  }, []);

  return (
    <AIProfileContext.Provider
      value={{
        aiProfile: aiProfile,
        setAIProfile: setAIProfile,
        saveAIProfile: saveAIProfile,
        getAIProfile: getAIProfile,
        editAIProfile: editAIProfile,
        removeAIProfile: removeAIProfile,
      }}
    >
      {children}
    </AIProfileContext.Provider>
  );
};
