import { Button, Stack, Typography, Stepper, Step, StepLabel } from '@mui/material';
import StartPage from '@/components/ai-profile/StartPage';
import BasicInfoForm from '@/components/ai-profile/BasicInfoForm';
import InterestsForm from '@/components/ai-profile/InterestsForm';
import CompletedForm from '@/components/ai-profile/CompletedForm';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@/contexts/AppContext';
import { AIForm, AIProfileScene } from '@/contexts/AIProfileContext';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import { useTranslation } from 'react-i18next';

const AIForms: AIForm[] = [
  { component: StartPage, title: 'Welcome to your AI Profile' },
  { component: BasicInfoForm, title: 'Basic information' },
  { component: InterestsForm, title: 'Interests' },
  { component: CompletedForm, title: 'AI Profile Updated' },
];

const AIProfilePage = () => {
  const { t } = useTranslation();
  const { setShowNavbar, pageHeight } = useContext(AppContext);
  const [currentForm, setCurrentForm] = useState(0);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const navigate = useNavigate();

  const goToPreviousForm = () => {
    if (currentForm > 0) setCurrentForm(currentForm - 1);
    else navigate('/settings', { replace: true });
  };

  const goToNextForm = () => {
    if (currentForm < AIForms.length - 1) {
      setCurrentForm(currentForm + 1);
    } else {
      navigate('/settings', { replace: true });
    }
  };

  const CurrentFormComponent = AIForms[currentForm].component;

  const nextButtonText =
    currentForm === AIForms.length - 1 ? 'Done' : currentForm == 0 ? 'Start' : 'Next';

  const previousButtonText = currentForm === 0 ? 'Cancel' : 'Back';

  useEffect(() => {
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, []);

  return (
    <AIProfileScene>
      <Stack
        spacing={2}
        px={2}
        pt={2}
        pb={2}
        sx={{
          minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={3}>
          <Stepper activeStep={currentForm} sx={{ px: 2, pt: 5, pb: 1 }}>
            {AIForms.map((form, index) => {
              const stepProps: { completed?: boolean } = { completed: index < currentForm };
              return (
                <Step key={form.title} {...stepProps}>
                  <StepLabel></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Typography variant="h5" component="h1" align="center">
            {t(AIForms[currentForm].title)}
          </Typography>
          <CurrentFormComponent />
        </Stack>
        <Stack spacing={2}>
          <Button variant="contained" onClick={goToNextForm}>
            {t(nextButtonText)}
          </Button>
          {currentForm !== AIForms.length - 1 && (
            <Button variant="outlined" onClick={goToPreviousForm}>
              {t(previousButtonText)}
            </Button>
          )}
        </Stack>
      </Stack>
    </AIProfileScene>
  );
};

export default AIProfilePage;
