import { ActivityContext } from '@/contexts/ActivityContext';
import { LocationContext } from '@/contexts/LocationContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationCard from './LocationCard';
import { Location } from '@/api/types/Location';
import ManageLocationsDialog from './ManageLocationsDialog';
import { Edit } from '@mui/icons-material';

type LocationsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const LocationsDialog = ({ open, onClose }: LocationsDialogProps) => {
  const { t } = useTranslation();
  const { locations } = useContext(LocationContext);
  const { activity, setActivity } = useContext(ActivityContext);

  const [manageLocationDialog, setManageLocationDialog] = useState(false);
  const [sortedLocations, setSortedLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined);

  const handleSelect = (selectedLocation: Location) => {
    if (activity) {
      setActivity({ ...activity, location: selectedLocation });
      onClose();
    } else {
      setManageLocationDialog(true);
      setSelectedLocation(selectedLocation);
    }
  };

  useEffect(() => {
    const filteredAndSorted = [...locations].sort((a, b) => {
      if (a.color && b.color && a.color < b.color) {
        return -1;
      }
      if (a.color && b.color && a.color > b.color) {
        return 1;
      }
      return 0;
    });
    setSortedLocations(filteredAndSorted);
  }, [locations]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-label="locations-dialog-title"
        aria-describedby="locations-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>{t('My locations')}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Stack spacing={2} direction="column">
            {sortedLocations.length === 0 ? (
              <Typography>{t('No locations found')}</Typography>
            ) : (
              sortedLocations.map((location) => (
                <LocationCard
                  key={location.locationId}
                  location={location}
                  icon={<Edit />}
                  onSelect={() => handleSelect(location)}
                  isClickable={true}
                />
              ))
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setManageLocationDialog(true);
            }}
            variant="contained"
            color="primary"
            sx={{ mr: 1 }}
          >
            {t('Add location')}
          </Button>
          <Button onClick={onClose} variant="contained" color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <ManageLocationsDialog
        open={manageLocationDialog}
        onClose={() => setManageLocationDialog(false)}
        location={selectedLocation}
      />
    </>
  );
};

export default LocationsDialog;
