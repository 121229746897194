import { useContext } from 'react';
import { Supervisor } from '@api';
import { SupervisorContext } from '@/contexts/SupervisorContext';
import { List, ListItem, ListItemText, ListItemButton, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useTranslation } from 'react-i18next';
import { formatClientOrSupervisor } from '@/utils/supervisor';

type SupervisorListProps = {
  onSelect?: () => void;
};
const SupervisorList = ({ onSelect }: SupervisorListProps) => {
  const { clients, setClient, currentClient } = useContext(SupervisorContext);
  const { t } = useTranslation();

  const handleSelect = (client: Supervisor) => {
    setClient(client);
    if (onSelect) onSelect();
  };

  return (
    <>
      <Typography variant="body1">{t('My clients')}</Typography>
      <List
        sx={{
          mt: '4px !important',
          pt: 0,
          pb: 0,
          border: '1px solid lightgray',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        {clients.map((client, index) => (
          <ListItem
            sx={{
              p: 0,
              width: '100%',
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            key={client.tag}
          >
            <ListItemButton
              onClick={() => handleSelect(client)}
              sx={{ pl: 4, pt: 2, pb: 2, pr: 2 }}
            >
              {currentClient?.tag === client.tag && (
                <CheckIcon
                  fontSize="small"
                  sx={{ position: 'absolute', left: 8, color: 'grey.700' }}
                />
              )}
              <ListItemText primary={`${formatClientOrSupervisor(client)}`} sx={{ pl: 0 }} />
              <SupervisorAccountIcon />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SupervisorList;
