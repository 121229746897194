import { Dialog, Stack, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadImageButton from './UploadImageButton';
import SelectIconButton from './SelectIconButton';
import GenerateImageButton from './GenerateImageButton';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useContext } from 'react';

type SelectFileDialogProps = {
  open: boolean;
  onLoad: () => void;
  onSelect: (file: File | null) => void;
  onClose: () => void;
  imageLimitReached?: boolean;
  setImageLimitReached?: (value: boolean) => void;
  disableAI?: boolean;
  aiDesc?: string;
};

const SelectFileDialog = (props: SelectFileDialogProps) => {
  const { t } = useTranslation();
  const { activity } = useContext(ActivityContext);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle textAlign={'center'}>{t('Add an image')}</DialogTitle>
        <DialogContent sx={{ px: 1 }}>
          <Stack direction={'row'} justifyContent="space-evenly" alignItems="start" mt={1}>
            <UploadImageButton onSelectFile={props.onSelect} />
            <SelectIconButton onSelectFile={props.onSelect} />
            {props.disableAI ? null : (
              <GenerateImageButton
                onLoad={props.onLoad}
                onSelectFile={props.onSelect}
                imageLimitReached={props.imageLimitReached}
                setImageLimitReached={props.setImageLimitReached}
                aiDesc={
                  props.aiDesc ||
                  `Something related to: ${activity.activityName} - ${activity.description}`
                }
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectFileDialog;
