import { useState } from 'react';
import { Button, SxProps, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import SelectFileDialog from './SelectFileDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';

const ImageInput = ({
  value,
  isFocused,
  onChange,
  sx,
  id = 'imageInput',
  onRemove,
  imageLoading,
  disableAI = false,
  aiDesc,
  isSubtask = false,
}: {
  value: string;
  isFocused?: boolean;
  onChange: { (result: File): void };
  sx?: SxProps;
  id?: string;
  onRemove?: { (): void };
  imageLoading?: { (loading: boolean): void };
  disableAI?: boolean;
  aiDesc?: string;
  isSubtask?: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imageLimitReached, setImageLimitReached] = useState<boolean>(false);
  const { setValid } = useContext(ActivityFormContext);

  const setImageLoading = (loading: boolean) => {
    setLoading(loading);
    imageLoading?.(loading);
  };

  const [mediaTypeDialogOpen, setMediaTypeDialogOpen] = useState(false);

  const handleButtonClick = () => {
    if (!loading) setMediaTypeDialogOpen(true);
  };

  const removeItem = () => {
    onRemove?.();
  };

  const selectFIle = (file: File | null) => {
    if (file) onChange(file);
    else {
      setError(true);
      onRemove?.();
    }
  };

  return (
    <>
      <SelectFileDialog
        open={mediaTypeDialogOpen}
        onClose={() => setMediaTypeDialogOpen(false)}
        onSelect={(file) => {
          setError(false);
          setMediaTypeDialogOpen(false);
          setImageLoading(false);
          selectFIle(file);
        }}
        onLoad={() => {
          setError(false);
          removeItem();
          setImageLoading(true);
          setMediaTypeDialogOpen(false);
        }}
        imageLimitReached={imageLimitReached}
        setImageLimitReached={setImageLimitReached}
        disableAI={disableAI}
        aiDesc={aiDesc}
      />
      <Button
        onClick={handleButtonClick}
        variant="outlined"
        sx={{
          position: 'relative',
          width: '6em',
          height: '6em',
          padding: 0,
          flexGrow: 2,
          boxShadow: isFocused ? '0 20px 20px 0 rgba(4, 6, 15, 0.08)' : 'none',
          border: isFocused ? `1px solid ${theme.palette.primary.main}` : '1px solid lightgray',
          borderRadious: '1em',
          ...sx,
        }}
      >
        {value && onRemove ? (
          <CancelIcon
            role="button"
            onClick={(e: any) => {
              e.stopPropagation();
              removeItem();
            }}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              backgroundColor: 'white',
              color: 'DarkSlateGray',
              borderRadius: '50%',
            }}
          />
        ) : null}
        {value ? (
          <img
            src={value}
            style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '1em' }}
            alt="Subtask item"
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
                <CircularProgress />
                {!isSubtask && <Typography variant="body2">{t('Generating image')}...</Typography>}
              </Stack>
            ) : (
              <>
                <PhotoCameraIcon fontSize="large" />
                <span style={{ fontSize: '12px' }}>{t('Add image')}</span>
              </>
            )}
          </div>
        )}
      </Button>
      {error && console.log('error', error)}
      {error && (
        <Typography variant="body2" mt={1} color="error" textAlign="center">
          {imageLimitReached
            ? t('You have reached the limit of 15 generated images.')
            : t('An error occurred, please try again.')}
        </Typography>
      )}
    </>
  );
};
export default ImageInput;
