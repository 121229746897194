import en from './en.json';
import sv from './sv.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import it from './it.json';
import pl from './pl.json';
import da from './da.json';
import nb from './nb.json';
import fi from './fi.json';

export const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
  pl: {
    translation: pl,
  },
  da: {
    translation: da,
  },
  nb: {
    translation: nb,
  },
  fi: {
    translation: fi,
  },
};
