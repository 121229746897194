import { AccordionSummary, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationsDialog from '../activity/createActivity/moreOptions/location/LocationsDialog';

const ProfileSettings = () => {
  const { t } = useTranslation();
  const [locationsDialog, setLocationsDialogOpen] = useState(false);

  return (
    <>
      <Stack>
        <AccordionSummary sx={{ mr: -1 }}>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <Typography variant="h6" color={'primary'}>
              {t('Locations')}
            </Typography>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => setLocationsDialogOpen(true)}
            >
              {t('My locations')}
            </Button>
          </Stack>
        </AccordionSummary>
      </Stack>
      <LocationsDialog open={locationsDialog} onClose={() => setLocationsDialogOpen(false)} />
    </>
  );
};

export default ProfileSettings;
