import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import NoteItem, { NoteItemProps } from './NoteItem';
import { isMobile } from 'react-device-detect';

//Wrapper for NoteItem that makes it sortable and draggable
const SortableNoteItem = (props: NoteItemProps) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.note.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };
  if (isMobile) {
    return (
      <NoteItem
        ref={setNodeRef}
        style={style}
        invisible={isDragging}
        {...props}
        {...attributes}
        {...listeners}
      />
    );
  } else {
    return <NoteItem ref={setNodeRef} style={style} invisible={isDragging} {...props} />;
  }
};

export default SortableNoteItem;
