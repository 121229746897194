import { AppContext } from '@/contexts/AppContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

type QrCodeDialogProps = {
  open: boolean;
  onClose: () => void;
};

const QrCodeDialog = ({ open, onClose }: QrCodeDialogProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const { palette } = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('Tag QR code')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          {user?.tag && (
            <QRCode
              value={user.tag}
              bgColor={palette.background.paper}
              fgColor={palette.mode === 'dark' ? palette.grey[200] : palette.primary.dark}
            />
          )}
          <Typography textAlign="center">
            {t('Ask a client to scan the QR code to send you a request')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeDialog;
