import { LocationApi } from '@/api/apis/LocationApi';
import type { Location } from '@/api/types/Location';
import { HttpStatusCode } from 'axios';
import { ResponseError } from '@api';
import { createContext, useEffect, useState } from 'react';

type props = {
  children?: React.ReactNode;
};

export type LocationForm = {
  component: () => JSX.Element;
  title: string;
};

type LocationContextType = {
  locations: Location[];
  createLocation: (location: Location) => Promise<Location | ResponseError | undefined>;
  fetchAllLocations: () => Promise<Location[] | ResponseError | undefined>;
  editLocation: (location: Location) => Promise<HttpStatusCode | ResponseError | undefined>;
  removeLocation: (locationId: number) => Promise<HttpStatusCode | ResponseError | undefined>;
};

export const LocationContext = createContext<LocationContextType>({} as LocationContextType);

export const LocationScene = ({ children }: props) => {
  const [locations, setLocations] = useState<Location[]>([] as Location[]);

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const createLocation = async (
    location: Location,
  ): Promise<Location | ResponseError | undefined> => {
    const res = await new LocationApi().createLocation({ location: location });

    if (res && !(res instanceof ResponseError))
      setLocations((prevLocations) => [...prevLocations, res as Location]);
    return res;
  };

  const fetchAllLocations = async (): Promise<Location[] | ResponseError | undefined> => {
    const res = await new LocationApi().getAllLocations();
    if (Array.isArray(res)) setLocations(res);
    return res;
  };

  const getLocation = async (locationId: number) => {
    return locations.find((location) => location.locationId === locationId);
  };

  const editLocation = async (
    location: Location,
  ): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new LocationApi().updateLocation({ location: location });

    if (res === 200) {
      const updatedLocations = locations.map((loc) => {
        if (loc.locationId === location.locationId) {
          return location;
        }
        return loc;
      });
      setLocations(updatedLocations);
    }
    return res;
  };

  const removeLocation = async (
    locationId: number,
  ): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new LocationApi().removeLocation({ locationId: locationId });

    if (res === 200) {
      const updatedLocations = locations.filter((loc) => loc.locationId !== locationId);
      setLocations(updatedLocations);
    }

    return res;
  };

  return (
    <LocationContext.Provider
      value={{
        locations: locations,
        createLocation: createLocation,
        fetchAllLocations: fetchAllLocations,
        editLocation: editLocation,
        removeLocation: removeLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
