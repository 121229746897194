import { Button, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SimpleList from '../utils/list/SimpleList';
import Delete from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { Supervisor, SupervisorApi, SupervisorConnection } from '@api';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import BasicDialog from '../utils/dialogs/BasicDialog';
import { AppContext } from 'src/contexts/AppContext';
import { formatClientOrSupervisor } from '@/utils/supervisor';
import { QrCode, QrCodeScannerOutlined } from '@mui/icons-material';
import QrCodeScannerDialog from './QrCodeScannerDialog';

const ManageSupervisorsSetting = () => {
  const { t } = useTranslation();
  const [newSupervisor, setNewSupervisor] = useState('');
  const [qrResult, setQrResult] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrScannerDialogOpen, setQrScannerDialogOpen] = useState(false);
  const [removeSupervisorDialog, setRemoveSupervisorDialog] = useState<undefined | Supervisor>();
  const { supervisors, refresh, supervisorRequests } = useContext(SupervisorContext);
  const { user } = useContext(AppContext);

  const outgoingRequests = supervisorRequests?.filter(
    (request) => request.client.tag === user?.tag,
  );

  const validateTag = (tag: string | null) => {
    const pattern = /^[A-Z]{4}#\d{4}$/;
    if (tag && pattern.test(tag)) {
      setError('');
      return true;
    }
    if (tag && tag.length === 0) setError('Tag cannot be empty');
    else setError('Invalid tag, expected format: ABCD#1234');
    return false;
  };

  const removeSupervisor = async () => {
    if (removeSupervisorDialog) {
      const res = await new SupervisorApi().removeSupervisor({
        supervisor: removeSupervisorDialog,
      });
      if (res) refresh();
    }
  };

  const addSupervisor = async () => {
    const tagToAdd = newSupervisor || qrResult;
    if (tagToAdd && validateTag(tagToAdd)) {
      const res = await new SupervisorApi().createSupervisor({ tag: tagToAdd });
      if (res) {
        refresh();
        setNewSupervisor('');
        setQrResult(null);
      }
    }
  };

  const handleAddSupervisor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const tagToValidate = newSupervisor || qrResult;
    if (tagToValidate && validateTag(tagToValidate)) {
      setDialogOpen(true);
    }
  };

  const getPendingListText = (request: SupervisorConnection) => {
    const { supervisor, acceptedConnection } = request;
    return `${supervisor.tag} - ${acceptedConnection === null ? t('Pending') : t('Declined')}`;
  };

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle2">{t('Information for supervisor client')}</Typography>
      <Stack spacing={2}>
        <Typography variant="subtitle1">{t('Add supervisor')}</Typography>
        <Stack direction="row" sx={{ width: '100%', gap: 1 }}>
          <TextField
            id="add-supervisor"
            placeholder={`${t('Tag')}`}
            variant="outlined"
            value={newSupervisor}
            onChange={(e) => {
              if (error) setError('');
              setNewSupervisor(e.target.value);
            }}
            error={!!error}
            helperText={t(error)}
            InputProps={{
              sx: {
                borderRadius: '1em',
              },
            }}
            sx={{
              flexGrow: 1,
              minHeight: '56px',
            }}
            multiline
            rows={1}
            onKeyDown={(event) => {
              if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSupervisor}
            sx={{
              borderRadius: '1em',
              p: 2,
              height: '56px',
            }}
          >
            {t('Add')}
          </Button>
        </Stack>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setQrScannerDialogOpen(true)}
          sx={{
            borderRadius: '1em',
            p: 2,
            height: '56px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1">{t('Scan a supervisors QR code')}</Typography>
            <QrCodeScannerOutlined fontSize="large" />
          </Stack>
        </Button>
      </Stack>
      {outgoingRequests.length > 0 ? (
        <Typography variant="subtitle1">{t('Pending supervisor requests')}</Typography>
      ) : null}
      <SimpleList
        items={outgoingRequests.map((request) => getPendingListText(request))}
        icon={<Delete color={'error'} sx={{ cursor: 'pointer' }} />}
        onIconClick={(tag) => {
          const croppedTag = tag.match(/[A-Z]{4}#\d{4}/)?.[0];
          const request = outgoingRequests.find(
            (request) => croppedTag && request.supervisor.tag.includes(croppedTag),
          );
          setRemoveSupervisorDialog(request?.supervisor);
        }}
      ></SimpleList>
      {supervisors.length > 0 ? (
        <Typography variant="subtitle1">{t('Supervisors')}</Typography>
      ) : null}
      <SimpleList
        items={supervisors.map((supervisor) => formatClientOrSupervisor(supervisor))}
        icon={<Delete color={'error'} sx={{ cursor: 'pointer' }} />}
        onIconClick={(tag) =>
          setRemoveSupervisorDialog(
            supervisors.find((supervisor) => formatClientOrSupervisor(supervisor).includes(tag)),
          )
        }
      ></SimpleList>
      <BasicDialog
        open={!!removeSupervisorDialog}
        content={{
          title: t('Remove supervisor'),
          body: `${formatClientOrSupervisor(removeSupervisorDialog)}: ${t(
            'Will be removed as your supervisor',
          )}`,
          confirmText: t('Remove'),
          cancelText: t('Cancel'),
        }}
        onClose={() => setRemoveSupervisorDialog(undefined)}
        onConfirm={removeSupervisor}
      ></BasicDialog>
      <BasicDialog
        open={dialogOpen}
        content={{
          title: t('Add supervisor'),
          body: `${newSupervisor || qrResult}: ${t('Will be added as your supervisor')}`,
          confirmText: t('Add'),
          cancelText: t('Cancel'),
        }}
        onClose={() => setDialogOpen(false)}
        onConfirm={addSupervisor}
      ></BasicDialog>
      <QrCodeScannerDialog
        open={qrScannerDialogOpen}
        onClose={() => setQrScannerDialogOpen(false)}
        onScan={(tag) => {
          setQrResult(tag);
          if (validateTag(tag)) {
            setDialogOpen(true);
          }
        }}
      />
    </Stack>
  );
};

export default ManageSupervisorsSetting;
