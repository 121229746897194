import { useState, useRef, useContext } from 'react';
import { Box, Stack } from '@mui/material';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Board } from '@api';
import { createPortal } from 'react-dom';
import { NotesContext } from 'src/contexts/NotesContext';
import { deleteItemAnimation } from '../DeleteItemAnimation';
import SortableBoardItem from './SortableBoardItem';
import BoardItem from './BoardItem';
import { reorderSortableList } from '@/utils/sorting';

type BoardListProps = {
  onRowClick: (board: Board) => void;
};

const BoardList = (props: BoardListProps) => {
  const [activeBoard, setActiveBoard] = useState<Board | null>(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { boards, setBoards, updateBoardOrder } = useContext(NotesContext);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);

  const handleMouseMove = (event: PointerEvent) => {
    const screenHeight = window.innerHeight;
    const pointerY = event.clientY;
    const bottomThreshold = screenHeight * 0.9;

    // Check if the pointer is in the bottom 10% of the screen
    if (pointerY >= bottomThreshold) {
      // The pointer is in the bottom 10% of the screen
      setDeleteItem(true);
    } else {
      setDeleteItem(false);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    document.body.addEventListener('pointermove', handleMouseMove);
    const activeBoard = boards.find((board) => board.id === event.active.id);
    if (activeBoard) {
      setActiveBoard(activeBoard);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    document.body.removeEventListener('pointermove', handleMouseMove);
    const { active, over } = event;
    if (active && over && active.id !== over?.id) {
      const sortedBoards = reorderSortableList(boards, active, over, 'id');
      setBoards(sortedBoards);
      updateBoardOrder(sortedBoards);
    }

    if (deleteItem) {
      deleteItemAnimation();
    }
    setActiveBoard(null);
  };

  const handleDragCancel = () => {
    setActiveBoard(null);
  };

  return (
    <>
      <Box sx={{ width: '100%', mt: 1, height: '100%' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={boards} strategy={verticalListSortingStrategy}>
            <Stack ref={containerRef} direction="column" px={0.5}>
              {boards.map((board) => (
                <SortableBoardItem
                  key={board.id}
                  board={board}
                  onClick={() => {
                    props.onRowClick(board);
                  }}
                />
              ))}
            </Stack>
          </SortableContext>
          {createPortal(
            <>
              <DragOverlay adjustScale>
                {activeBoard ? (
                  <BoardItem id="dragOverlayElement" board={activeBoard} isDragging />
                ) : null}
              </DragOverlay>
            </>,
            document.body,
          )}
        </DndContext>
      </Box>
    </>
  );
};

export default BoardList;
