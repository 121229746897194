import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export interface Sortable {
  position: number;
}

export const reorderSortableList = <T extends Sortable>(
  list: Array<T>,
  active: Active,
  over: Over | null,
  idKey: keyof T,
): Array<T> => {
  const oldIndex = list.findIndex((obj) => obj[idKey] === active.id);
  const newIndex = list.findIndex((obj) => obj[idKey] === over?.id);
  return sortUpdatedItems(arrayMove(list, oldIndex, newIndex));
};

export const sortUpdatedItems = <T extends Sortable>(items: Array<T>): Array<T> => {
  const sortedItems = items.map((item, index) => ({ ...item, position: index + 1 }));
  return sortedItems;
};
