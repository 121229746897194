import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, ButtonBase, Stack } from '@mui/material';
import DottedTimer from './DottedTimer';
import useTimerState from './useTimerState';
import SuccessAnimation from './SuccessAnimation';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

type TimerProps = {
  minutes: number;
  seconds?: number;
  isRunning: boolean;
  onTimerEnd?: () => void;
  displayMode?: 'default' | 'quarters' | 'minutes';
  timerId?: number;
  color?: string;
  completed?: boolean;
  onClick?: () => void;
};

const Timer: React.FC<TimerProps> = ({
  minutes,
  seconds = 0,
  isRunning,
  onTimerEnd,
  displayMode = 'default',
  timerId,
  color = 'blue',
  completed = false,
  onClick,
}) => {
  const { time, timerDone } = useTimerState({ minutes, seconds, isRunning, onTimerEnd, timerId });

  const containerRef = useRef<HTMLButtonElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const { t } = useTranslation();

  const { palette } = useTheme();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const getResponsiveFontSize = () => {
    const baseFontSize = 16;
    const scaleFactor = 0.15;
    return Math.max(baseFontSize, containerWidth * scaleFactor);
  };

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    if (displayMode === 'minutes') {
      if (hours > 0 && minutes > 0)
        return `${hours.toString()}h \n ${minutes.toString()}${t('min')}`;
      if (hours > 0 && minutes == 0) return `${hours.toString()}h `;

      return `${minutes.toString()}${t('min')}`;
    }

    if (hours > 0)
      return `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculatePercentage = (time: number) => {
    const maxMinutes = 60;
    const maxSeconds = maxMinutes * 60;
    return Math.max(0, Math.min(100, (time / maxSeconds) * 100));
  };

  const showQuarters = displayMode === 'quarters';

  const wrapperStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: showQuarters ? '1.25em' : '0',
    borderRadius: '50%',
  };

  const hidden: React.CSSProperties = {
    transform: 'scale(4) translate(0, -25%)',
    opacity: 0,
    transition: 'all 0.35s ease-out',
  };

  const visible: React.CSSProperties = {
    opacity: 1,
    transition: 'opacity 0.1s linear',
    transitionDelay: '0.1s',
  };

  const quarters = [
    { value: 0, left: '50%', top: '0', transform: 'translate(-50%, 0)' },
    { value: 15, left: '100%', top: '50%', transform: 'translate(-100%, -50%)' },
    { value: 30, left: '50%', top: '100%', transform: 'translate(-50%, -100%)' },
    { value: 45, left: '0', top: '50%', transform: 'translate(0, -50%)' },
  ];

  const pulseText = `
    @keyframes pulseText {
      0% {
        color: rgba(0,0,0,0.7);
      }
      50% {
        color: rgba(0,0,0,0.2);
      }
    }
  `;

  return (
    <ButtonBase
      sx={wrapperStyle}
      role={onClick ? 'button' : ''}
      onClick={() => onClick && onClick()}
      ref={containerRef}
      disabled={onClick === undefined || completed || timerDone}
    >
      <style>{pulseText}</style>
      <DottedTimer
        percentage={calculatePercentage(time)}
        color={color}
        stroke={palette.black}
        backgroundColor={palette.background.default}
        text
        style={{ width: '100%' }}
      />
      {completed === true ? (
        <Box
          sx={{
            position: 'absolute',
          }}
        >
          <SuccessAnimation />
        </Box>
      ) : timerDone ? (
        <>
          <Typography
            variant="h2"
            sx={{
              fontSize: getResponsiveFontSize(),
              position: 'absolute',
            }}
          >
            {formatTime(time)}
          </Typography>
          {onClick && (
            <Stack
              position="absolute"
              top={`calc(50% + ${getResponsiveFontSize() / 2}px)`}
              justifyContent="start"
              alignItems="center"
              direction="column"
            >
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  animation: 'pulseText 6s ease-in-out infinite alternate',
                }}
              >
                {t('No time left')}
              </Typography>
            </Stack>
          )}
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            sx={{
              fontSize: getResponsiveFontSize(),
              position: 'absolute',
            }}
          >
            {formatTime(time)}
          </Typography>
          {onClick && (
            <Stack
              position="absolute"
              top={`calc(50% + ${getResponsiveFontSize() / 2}px)`}
              justifyContent="start"
              alignItems="center"
              direction="column"
            >
              <Box sx={{ position: 'relative', height: '1.25em', width: '5em' }}>
                <PauseIcon
                  sx={{
                    color: 'grey.700',
                    position: 'absolute',
                    margin: 'auto',
                    left: 0,
                    right: 0,
                    top: 0,
                    opacity: 1,
                    ...(isRunning ? visible : hidden),
                  }}
                />
                <PlayArrowIcon
                  sx={{
                    color: 'grey.700',
                    position: 'absolute',
                    margin: 'auto',
                    left: 0,
                    right: 0,
                    top: 0,
                    opacity: 1,
                    ...(isRunning ? hidden : visible),
                  }}
                />
              </Box>
              {!isRunning && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    animation: 'pulseText 6s ease-in-out infinite alternate',
                  }}
                >
                  {t('Paused')}
                </Typography>
              )}
            </Stack>
          )}
        </>
      )}
      {showQuarters && (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'transparent',
            zIndex: 10,
          }}
        >
          {quarters.map((quarter) => (
            <Typography
              style={{
                position: 'absolute',
                left: quarter.left,
                top: quarter.top,
                opacity: 1,
                transform: quarter.transform,
              }}
              key={quarter.value}
            >
              {quarter.value}
            </Typography>
          ))}
        </Box>
      )}
    </ButtonBase>
  );
};

export default Timer;
