import { Typography, TextField, Stack, Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { AIProfileContext } from '@/contexts/AIProfileContext';
import { useTranslation } from 'react-i18next';
import { getEnumKeyByValue } from '@/utils/enum';

enum ActivityEnum {
  SPORTS = 'Sport/Träning',
  RELAXATION = 'Avkoppling',
  CULTURE = 'Kultur',
  NATURE = 'Natur',
  HOBBY = 'Hobby',
  SOCIAL = 'Socialt',
  READING = 'Läsning/Litteratur',
  MOVIE = 'Film',
  COOKING = 'Matlagning',
  GAME = 'Spel',
  GARDEN = 'Trädgård',
  HOUSEWORK = 'Hemsysslor',
  MUSIC = 'Musik',
  ARTS = 'Konst & Hantverk',
  EDUCATION = 'Utbildning',
}

const InterestsForm = () => {
  const { aiProfile, setAIProfile } = useContext(AIProfileContext);
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const activity = getEnumKeyByValue(ActivityEnum, e.currentTarget.textContent || '');
    //const activity = e.currentTarget.textContent;
    if (activity) {
      if (aiProfile.preferredActivities.includes(activity)) {
        const newActivities = aiProfile.preferredActivities.filter((act) => act !== activity);
        setAIProfile({ ...aiProfile, preferredActivities: newActivities });
      } else {
        setAIProfile({
          ...aiProfile,
          preferredActivities: [...aiProfile.preferredActivities, activity],
        });
      }
    }
  };

  return (
    <Stack spacing={4}>
      <Typography>{t('Which activities do you prefer?')}:</Typography>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        justifyContent="space-evenly"
        spacing={1}
        rowGap={1.5}
        mt={'12px !important'}
      >
        {Object.entries(ActivityEnum).map(([activityKey, activity]) => (
          <Chip
            key={activity}
            label={t(activity)}
            onClick={handleClick}
            variant={aiProfile.preferredActivities.includes(activityKey) ? 'filled' : 'outlined'}
            color="primary"
            icon={
              aiProfile.preferredActivities.includes(activity) ? (
                <CheckIcon fontSize="small" />
              ) : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default InterestsForm;
