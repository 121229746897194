import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { NoPhotography } from '@mui/icons-material';
import QRScanner from '../utils/QrScanner';

type QrCodeScannerDialogProps = {
  open: boolean;
  onClose: () => void;
  onScan: (tag: string) => void;
};

type CameraPermissionState = 'denied' | 'not-found' | 'granted' | 'pending';

const QrCodeScannerDialog = ({ open, onClose, onScan }: QrCodeScannerDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [cameraPermissionState, setCameraPermissionState] =
    useState<CameraPermissionState>('pending');

  useEffect(() => {
    if (open) {
      requestCameraPermission();
    }
  }, [open]);

  const requestCameraPermission = async () => {
    setCameraPermissionState('pending');
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermissionState('granted');
    } catch (err: any) {
      if (err.name === 'NotFoundError') {
        setCameraPermissionState('not-found');
      } else {
        setCameraPermissionState('denied');
      }
    }
  };

  const handleRetry = () => {
    requestCameraPermission();
  };

  const renderErrorContent = (message: string, buttonText: string) => (
    <Stack spacing={2} alignItems="center" sx={{ width: '100%', textAlign: 'center' }}>
      <NoPhotography sx={{ fontSize: 80, color: theme.palette.error.main }} />
      <Typography variant="h6" color="error">
        {message}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleRetry}>
        {buttonText}
      </Button>
    </Stack>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="qr-code-scanner-title"
      aria-describedby="qr-code-scanner-description"
    >
      <DialogTitle id="qr-code-scanner-title">{t('Scan QR code')}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '38vh',
          justifyContent: 'center',
          overflow: 'visible',
        }}
      >
        <Stack spacing={2} justifyContent="center" alignItems="center">
          {cameraPermissionState === 'pending' && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {cameraPermissionState === 'not-found' &&
            renderErrorContent(t('Camera not found'), t('Try again'))}
          {cameraPermissionState === 'denied' &&
            renderErrorContent(t('Could not access camera'), t('Allow camera access'))}
          {cameraPermissionState === 'granted' && (
            <Box sx={{ mt: 4 }}>
              <QRScanner
                onScan={(result) => {
                  onScan(result);
                  onClose();
                }}
                onError={() => setCameraPermissionState('denied')}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeScannerDialog;
