import debounce from 'debounce';

const LOCATION_URL = 'https://nominatim.openstreetmap.org';

const eu_country_codes = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const fetchAddresses = async (searchTerm: string, lang: string): Promise<string[]> => {
  if (searchTerm.length < 4) return [];

  const url = `${LOCATION_URL}/search?q=${encodeURIComponent(
    searchTerm,
  )}&format=json&addressdetails=1&extratags=1&namedetails=0&limit=4&countrycodes=${eu_country_codes.join()}&accept-language=${lang}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    const uniqueAddresses = new Set<string>(data.map((item: any) => formatAddress(item)));

    return Array.from(uniqueAddresses);
  } catch (error) {
    console.error('Error when fetching addresses:', error);
    return [];
  }
};

export const fetchAddressFromLatLng = async (
  lat: number,
  lon: number,
  lang: string,
): Promise<string | null> => {
  const url = `${LOCATION_URL}/reverse?format=json&lat=${lat}&lon=${lon}&countrycodes=${eu_country_codes.join()}&accept-language=${lang}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return formatAddress(data);
  } catch (error) {
    console.error('Error when fetching address from lat/lng:', error);
    return null;
  }
};

export const openGoogleMaps = (address: string): void => {
  window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`);
};

const formatAddress = (address: any): string => {
  if (!address.address) return '';

  const { house_number, road, city, county, country } = address.address;
  const street = road && house_number ? `${road} ${house_number}` : road || house_number;

  return [street, city, county, country].filter(Boolean).join(', ');
};

export const debouncedFetchAddresses = debounce(fetchAddresses, 300);
