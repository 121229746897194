import { Note } from '@api';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import ConfirmDialog from '@/components/utils/ConfirmDialog';
import { NotesContext } from 'src/contexts/NotesContext';
import { Delete } from '@mui/icons-material';

type DeleteNoteProps = {
  note: Note;
  noteBoardId: number;
  onClose: () => void;
};

const DeleteNote = ({ note, noteBoardId, onClose }: DeleteNoteProps) => {
  const [sending, setSending] = useState(false);
  const { deleteNote } = useContext(NotesContext);

  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const onRemove = async () => {
    setSending(true);
    if (note) {
      await deleteNote(note.id, noteBoardId);
    }
    onClose();
    setSending(false);
  };

  return (
    <>
      <Delete
        onClick={() => setConfirmOpen(true)}
        sx={{
          cursor: 'pointer',
        }}
      ></Delete>
      <ConfirmDialog
        bodyText={t('Are you sure you want to delete this Note?')}
        onClose={closeConfirmDialog}
        onConfirm={onRemove}
        title={t('Delete Note')}
        open={confirmOpen}
        yesNo
      />
    </>
  );
};

export default DeleteNote;
