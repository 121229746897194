import LanguageSetting from './LanguageSetting';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import SuperviseModeToggle from './SuperviseModeToggle';
import NotificationToggle from './NotificationToggle';
import DarkModeToggle from './DarkModeToggle';
import ManageSupervisorsSetting from './ManageSupervisorsSetting';
import ManageClientsSetting from './ManageClientsSetting';
import LegalSetting from './LegalSetting';
import { BasicDialogContent } from '../utils/dialogs/BasicDialog';
import AISettings from './AISettings';
import ProfileSettings from './ProfileSettings';

export type SettingsConfig = {
  id: string;
  title: string;
  titleColor?: string;
  accordionContent?: () => JSX.Element;
  icon?: () => JSX.Element;
  hideAsSupervisor?: boolean;
  onlyAsSupervisor?: boolean;
  dialog?: BasicDialogContent;
  accordionHeader?: (props: SettingsConfig) => JSX.Element;
  disabled?: boolean;
};

export const settingsConfig: SettingsConfig[] = [
  {
    id: 'supervise',
    title: 'Supervise mode',
    accordionHeader: SuperviseModeToggle,
  },
  {
    id: 'notificationToggle',
    title: 'Notifications',
    accordionHeader: NotificationToggle,
  },
  {
    id: 'darkMode',
    title: 'Dark mode',
    accordionHeader: DarkModeToggle,
  },
  {
    id: 'profile',
    title: 'Profile',
    accordionContent: ProfileSettings,
  },
  {
    id: 'AISettings',
    title: 'AI Settings',
    accordionContent: AISettings,
    hideAsSupervisor: true,
  },
  {
    id: 'supervisorManagement',
    title: 'Manage supervisors',
    accordionContent: ManageSupervisorsSetting,
    hideAsSupervisor: true,
  },
  {
    id: 'supervisorManagement',
    title: 'Manage clients',
    accordionContent: ManageClientsSetting,
    onlyAsSupervisor: true,
  },
  {
    id: 'language',
    title: 'Language',
    accordionContent: LanguageSetting,
  },
  {
    id: 'PrivacyPolicyAndTermsOfService',
    title: 'Legal & Licenses',
    accordionContent: LegalSetting,
  },
  {
    id: 'deleteAccount',
    title: 'Delete account',
    titleColor: 'error.main',
    icon: () => <DeleteIcon sx={{ color: 'error.main' }} />,
    dialog: {
      title: 'Delete account',
      body: 'Are you sure you want to delete your account?',
      confirmText: 'Yes',
      cancelText: 'No',
      confirmButtonColor: 'error',
    },
  },
  {
    id: 'logout',
    title: 'Logout',
    icon: () => <LogoutIcon />,
    dialog: {
      title: 'Logout',
      body: 'Are you sure you want to logout?',
      confirmText: 'Logout',
      cancelText: 'No',
    },
  },
];
