import { Stack, Typography, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StartPage = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Typography variant="body1">
        {t(
          'To give you the best possible suggestions for activities, you can choose to share some information about yourself',
        )}{' '}
        <strong>
          {t('All data you share is voluntary and is only used to improve your experience.')}
        </strong>
      </Typography>
      <Typography variant="h6">{t('How does it work?')}</Typography>
      <List sx={{ listStyleType: 'disc', pl: 4, mt: '0px !important' }}>
        <ListItem sx={{ display: 'list-item' }}>
          {t('You will now be asked to answer some questions about yourself.')}
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          {t('Each question is voluntary – you can skip any questions you do not want to answer.')}
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          {t(
            'Your AI profile helps improve and customize your experience based on the information you share.',
          )}
        </ListItem>
      </List>
    </Stack>
  );
};

export default StartPage;
