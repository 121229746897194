import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  MenuItem,
  TextField,
  Popper,
  Paper,
  InputLabel,
  Stack,
  Button,
  useTheme,
  Typography,
} from '@mui/material';
import React, { useState, ChangeEvent, useCallback, useContext, useEffect, useRef } from 'react';
import { fetchAddresses } from '@/utils/location';
import debounce from 'debounce';
import i18n from '@/i18n/i18n';
import { ActivityContext } from '@/contexts/ActivityContext';
import { LocationContext } from '@/contexts/LocationContext';
import { Location } from '@/api/types/Location';

type LocationInputProps = {
  value: string;
  onChange: (value: string) => void;
  error: string | boolean;
  setError: (error: string | boolean) => void;
  isManagingLocations?: boolean;
};

const LocationInput = ({
  value,
  onChange,
  error,
  setError,
  isManagingLocations,
}: LocationInputProps) => {
  const { t } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const { palette } = useTheme();
  const { activity, setActivity } = useContext(ActivityContext);
  const { locations } = useContext(LocationContext);
  const [suggestions, setSuggestions] = useState<(string | Location)[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [textFieldValue, setTextFieldValue] = useState<string>(value);

  const textFieldWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTextFieldValue(value);
  }, [value]);

  const searchSavedLocations = (searchTerm: string): (string | Location)[] =>
    locations
      .filter(({ locationName }) => locationName?.toLowerCase().includes(searchTerm.toLowerCase()))
      .slice(0, 4);

  const debouncedFetch = useCallback(
    debounce(async (searchTerm: string) => {
      setLoading(true);
      setSuggestions([]);
      try {
        const results = await fetchAddresses(searchTerm, lang);
        if (isManagingLocations) {
          setSuggestions(results || []);
        } else {
          setSuggestions([...searchSavedLocations(searchTerm), ...(results || [])]);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [lang, locations],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setTextFieldValue(newValue);

    if (isManagingLocations) {
      onChange(newValue);
    }

    if (!newValue) {
      setSuggestions([]);
      setError(false);
      return;
    }

    if (newValue.length < 3) return setSuggestions([]);
    if (newValue.length > 256) return setError('Address is too long');

    debouncedFetch(newValue);
  };

  const handleSelect = (suggestion: string | Location) => {
    if (typeof suggestion !== 'string') {
      setActivity({ ...activity, location: suggestion });
      const locationValue = suggestion.locationAddress || suggestion.locationName || '';
      setTextFieldValue(locationValue);
      onChange(locationValue);
    } else {
      setTextFieldValue(suggestion);
      onChange(suggestion);
    }

    setSuggestions([]);
    setAnchorEl(null);
  };

  const handleBlur = () => {
    if (textFieldValue.length > 0) {
      onChange(textFieldValue);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Stack>
        <Stack direction="row" spacing={2}>
          <InputLabel htmlFor="address">
            {t(isManagingLocations ? 'Address' : 'Activity location')}
          </InputLabel>
        </Stack>
        <Stack ref={textFieldWrapperRef} direction="row" spacing={1.5} alignItems="center">
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('Enter a location') + '...'}
            value={textFieldValue}
            onChange={handleInputChange}
            autoComplete="off"
            error={!!error}
            helperText={error || ''}
            onBlur={handleBlur}
            onFocus={(e) => setAnchorEl(e.currentTarget)}
            InputProps={{
              endAdornment: loading ? <CircularProgress size={20} /> : null,
            }}
          />
        </Stack>
      </Stack>
      <Popper
        open={suggestions.length > 0 && !!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          zIndex: 1300,
          width: textFieldWrapperRef.current?.clientWidth || 'auto',
          maxWidth: '100%',
        }}
      >
        <Paper
          sx={{
            boxShadow: 4,
            width: '100%',
            maxHeight: 250,
            overflowY: 'auto',
          }}
        >
          {suggestions.map((suggestion, index) => {
            const isLocation = typeof suggestion !== 'string';

            return (
              <MenuItem
                key={index}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleSelect(suggestion)}
                sx={{
                  py: 1.5,
                  borderBottom: `1px solid ${palette.grey[800]}`,
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%',
                  ...(isLocation && {
                    borderLeft: `8px solid ${suggestion.color || 'grey'}`,
                  }),
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ minWidth: 0, flex: 1, overflow: 'hidden' }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      flex: 1,
                    }}
                  >
                    {isLocation ? suggestion.locationName || '' : suggestion}
                  </Typography>
                  {isLocation && suggestion.locationAddress && (
                    <Typography
                      color={palette.grey[500]}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flexShrink: 0,
                        maxWidth: '50%',
                      }}
                    >
                      {suggestion.locationAddress}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            );
          })}
        </Paper>
      </Popper>
    </>
  );
};

export default LocationInput;
