import { Box, Button, Dialog, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityContext } from 'src/contexts/ActivityContext';

type CustomDurationDialogProps = {
  open: boolean;
  onChange: (newVal: number) => void;
  onClose: () => void;
};

const CustomDurationDialog = (props: CustomDurationDialogProps) => {
  const { activity, setActivity } = useContext(ActivityContext);
  const [value, setValue] = useState<number>(activity.duration);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    setValue(activity.duration);
  }, [activity.duration]);

  useEffect(() => {
    if (hour < 0) {
      setHour(0);
    }
  }, [hour]);

  const handleChange =
    (
      setValue: {
        (value: SetStateAction<number>): void;
        (value: SetStateAction<number>): void;
        (arg0: number): void;
      },
      max: number,
    ) =>
    (e: { target: { value: string } }) => {
      if (e.target.value === '') setValue(-1);
      else if (+e.target.value > max) setValue(max);
      else setValue(+e.target.value);
    };

  const getTotalTime = () => {
    const totalMin = hour * 60 + min;
    return totalMin;
  };

  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Stack direction={'column'} p={3} spacing={2}>
        <Box>
          <InputLabel sx={{ paddingBottom: '5px' }} htmlFor="customDurationHour">
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              {t('Custom duration (hour : minutes)')}
            </Typography>
          </InputLabel>
          <TextField
            id="customDurationHour"
            type="number"
            value={hour >= 0 ? hour : ''}
            sx={{
              width: '3.5em',
              height: '1.5em',
              '& input': { padding: '5px', textAlign: 'center' },
            }}
            onChange={handleChange(setHour, 24)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0, max: 24 }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
            }}
          />
          &nbsp;:&nbsp;
          <TextField
            id="customDurationMin"
            type="number"
            value={min >= 0 ? min : ''}
            sx={{
              width: '3.5em',
              height: '1.5em',
              '& input': { padding: '5px', textAlign: 'center' },
            }}
            onChange={handleChange(setMin, 59)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0, max: 59 }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              props.onChange(getTotalTime());
              props.onClose();
            }}
            variant={'contained'}
            disabled={hour < 0 && min < 0}
          >
            Ok
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default CustomDurationDialog;
