import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AiApi, languageKey } from '@api';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const GenerateImageButton = ({
  onLoad,
  onSelectFile,
  imageLimitReached,
  setImageLimitReached,
  aiDesc,
}: {
  onLoad: () => void;
  onSelectFile: (file: File | null) => void;
  imageLimitReached?: boolean;
  setImageLimitReached?: (value: boolean) => void;
  aiDesc: string;
}) => {
  const { activity, imageCache, setImageCache } = useContext(ActivityContext);
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const isLanguageKey = (lang: any): lang is languageKey => {
    return ['sv', 'en', 'de', 'fr', 'es', 'it', 'pl', 'nb', 'da', 'fi'].includes(lang);
  };

  const handleButtonClick = async () => {
    //Chech if we have a cache for the activity
    if (imageCache && imageCache.title === aiDesc) {
      onSelectFile(imageCache.image);
      return;
    } else if (imageCache) {
      // The cache is for a different activity
      setImageCache?.(null);
    }

    onLoad();
    try {
      const data = await new AiApi().generateImage(isLanguageKey(lang) ? lang : 'sv', aiDesc);
      if (data) {
        setImageCache?.({ title: aiDesc, image: data });
        onSelectFile(data);
      }
    } catch (error: any) {
      const message = error.response?.data?.errorMessage;
      if (message === 'You have reached the limit of 15 generated images.') {
        setImageLimitReached?.(true);
      }
      console.error('Error generating image:', error);
      onSelectFile(null);
    }
  };

  return (
    <Button onClick={handleButtonClick}>
      <Stack alignItems={'center'}>
        <AutoFixHighIcon fontSize="large" />
        {t('Generate image')}
      </Stack>
    </Button>
  );
};

export default GenerateImageButton;
