import { Location } from '@/api/types/Location';
import ConfirmDialog from '@/components/utils/ConfirmDialog';
import { LocationContext } from '@/contexts/LocationContext';
import { Delete } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteLocationProps = {
  location: Location;
  onClose: () => void;
};

const DeleteLocation = ({ location, onClose }: DeleteLocationProps) => {
  const { t } = useTranslation();

  const [sending, setSending] = useState(false);
  const { removeLocation } = useContext(LocationContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const onRemove = async () => {
    setSending(true);
    if (location) {
      await removeLocation(location.locationId);
    }
    onClose();
    setSending(false);
  };

  return (
    <>
      <Delete
        onClick={() => setConfirmOpen(true)}
        sx={{
          cursor: 'pointer',
        }}
      ></Delete>
      <ConfirmDialog
        bodyText={t(
          'Are you sure you want to delete this Location. This action will remove the location from all activities.',
        )}
        onClose={closeConfirmDialog}
        onConfirm={onRemove}
        title={t('Delete location')}
        open={confirmOpen}
        yesNo
      />
    </>
  );
};

export default DeleteLocation;
