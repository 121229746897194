import { Location } from '@/api/types/Location';
import { Card, CardContent, IconButton, Stack, Typography, useTheme } from '@mui/material';
import ManageLocationsDialog from './ManageLocationsDialog';
import { useContext, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';

type LocationCardProps = {
  location: Location;
  icon?: JSX.Element;
  onIconClick?: () => void;
  onSelect?: () => void;
  isClickable: boolean;
};

const LocationCard = ({
  location,
  icon,
  onIconClick,
  onSelect,
  isClickable,
}: LocationCardProps) => {
  const { palette } = useTheme();
  const { activity } = useContext(ActivityContext);

  const [handleLocationDialog, setHandleLocationDialog] = useState(false);

  return (
    <>
      <Card
        sx={{
          backgroundColor: palette.background.paper,
          border: '1px solid lightgray',
          borderLeft: `10px solid ${location.color ? location.color : palette.grey[500]}`,
          borderRadius: '8px',
          boxShadow: 1,
          cursor: isClickable ? 'pointer' : 'default',
          transition: '0.2s',
          '&:hover': isClickable
            ? {
                backgroundColor: palette.action.hover,
                boxShadow: 3,
              }
            : undefined,
        }}
        onClick={isClickable ? onSelect : undefined}
      >
        <CardContent sx={{ py: 1.6, px: 2, '&:last-child': { pb: 1.6 } }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Stack spacing={0} px={0.2} flex={1} minWidth={0}>
              <Typography
                variant="h6"
                fontWeight={'bold'}
                color={palette.primary.main}
                sx={{
                  m: 0,
                  lineHeight: 1.2,
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
              >
                {location.locationName}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={80}
                sx={{
                  textTransform: 'capitalize',
                  m: 0,
                  lineHeight: 1.2,
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                color={palette.primary.main}
              >
                {location.locationAddress}
              </Typography>
            </Stack>

            {activity && icon && (
              <IconButton
                color="primary"
                sx={{ flexShrink: 0, alignSelf: 'flex-start', ml: 1 }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (onIconClick) {
                    onIconClick();
                  } else {
                    setHandleLocationDialog(true);
                  }
                }}
              >
                {icon}
              </IconButton>
            )}
          </Stack>
        </CardContent>
      </Card>
      <ManageLocationsDialog
        location={location}
        open={handleLocationDialog}
        onClose={() => {
          setHandleLocationDialog(false);
        }}
      />
    </>
  );
};

export default LocationCard;
