import { createContext, useContext, useState, useEffect } from 'react';
import { Activity, ActivityApi, ActivityInstance, Client, User } from '@api';
import { AppContext } from './AppContext';
import { CalendarContext } from './CalendarContext';
import { SupervisorContext } from './SupervisorContext';
import { ResponseError } from '../api/runtime';
import { HttpStatusCode } from 'axios';
import { Location } from '@/api/types/Location';
import { LocationContext } from './LocationContext';

//Used for caching AI-generated images
type ImageCache = {
  title: string;
  image: File;
};

type props = {
  children?: React.ReactNode;
  activityId?: number;
  useStoredActivity?: boolean;
};

export type ActivityContextType = {
  activity: Activity;
  setActivity: (activity: Activity) => void;
  createActivity?: () => Promise<HttpStatusCode | ResponseError | undefined>;
  editActivity: () => Promise<HttpStatusCode | ResponseError | undefined>;
  createOffspring: (
    instance: ActivityInstance,
  ) => Promise<HttpStatusCode | ResponseError | undefined>;
  removeActivity: () => Promise<HttpStatusCode | ResponseError | undefined>;
  getActivity?: (activityId: number) => void;
  validateRecurrence?: () => void;
  imageCache?: ImageCache | null;
  setImageCache?: (imageCache: ImageCache | null) => void;
};

export const ActivityContext = createContext<ActivityContextType>({} as ActivityContextType);

const getDefaultActivity = (user: User, client?: Client): Activity => ({
  activityId: 0,
  activityName: '',
  creatorTag: `${user.tag}`,
  startDate: new Date(0),
  duration: -1,
  description: '', // toDo: should be null, update when backend has changed
  location: null,
  picture: '',
  video: '',
  recurringActivity: null,
  subtasks: [],
});

//ToDo This component is rendered twice when setActivity is called, it should not do that
export const ActivityScene = ({ children, activityId, useStoredActivity }: props) => {
  const { user } = useContext(AppContext);
  const { locations } = useContext(LocationContext);
  const { currentClient } = useContext(SupervisorContext);
  const { refreshWeek } = useContext(CalendarContext);

  //Cache generated image based on activity Titile
  const [imageCache, setImageCache] = useState<ImageCache | null>(null);

  const [activity, setActivity] = useState<Activity>(
    activityId ? ({} as Activity) : getDefaultActivity(user ?? ({} as User), currentClient),
  );

  useEffect(() => {
    if (activity?.location) {
      const updatedLocation = locations.find(
        (loc) => loc.locationId === activity.location?.locationId,
      );

      if (!updatedLocation) {
        setActivity((prevActivity) => ({ ...prevActivity, location: null }));
      } else if (updatedLocation !== activity.location) {
        setActivity((prevActivity) => ({ ...prevActivity, location: updatedLocation }));
      }
    }
  }, [locations, activity?.location]);

  const createActivity = async (): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new ActivityApi().createActivity({
      activity,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) refreshWeek();
    return res;
  };

  const editActivity = async (): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new ActivityApi().editActivity({
      activity,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) refreshWeek();
    return res;
  };

  const createOffspring = async (
    instance: ActivityInstance,
  ): Promise<HttpStatusCode | ResponseError | undefined> => {
    // an offspring cant be a recurring activity
    const res = await new ActivityApi().createActivityOffspring({
      activityInstance: instance,
      activity: { ...activity, recurringActivity: null },
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) refreshWeek();
    return res;
  };

  // Delete activities
  const removeActivity = async (): Promise<HttpStatusCode | ResponseError | undefined> => {
    const res = await new ActivityApi().removeActivity({
      activityId: activity.activityId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) refreshWeek();
    return res;
  };

  const validateRecurrence = () => {
    if (activity.recurringActivity && activity.recurringActivity.recurringEndDate) return;
    setActivity({
      ...activity,
      recurringActivity: null,
    });
    return;
  };

  const getActivity = async (activityId: number) => {
    const res = await new ActivityApi().getActivity({
      activityId: activityId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res) setActivity(res);
    else setActivity(getDefaultActivity(user ?? ({} as User)));
  };

  const getStoredActivity = (): Activity | undefined => {
    const storedActivity = sessionStorage.getItem('formActivity');
    if (!storedActivity) return undefined;

    const parsedActivity = JSON.parse(storedActivity);

    // If we are editing an activity, we need to check if the stored activity is the same as the one we are editing
    // New activities also have id 0, and not undefined
    const currentId = activityId ?? 0;

    if (parsedActivity.activityId === currentId) return parsedActivity;
    return undefined;
  };

  useEffect(() => {
    const storedActivity = useStoredActivity ? getStoredActivity() : null;
    if (storedActivity) {
      //We need to parse the dates
      storedActivity.startDate = new Date(storedActivity.startDate);
      if (storedActivity.recurringActivity && storedActivity.recurringActivity.recurringEndDate)
        storedActivity.recurringActivity.recurringEndDate = new Date(
          storedActivity.recurringActivity.recurringEndDate,
        );
      setActivity(storedActivity);
    } else if (activityId) {
      getActivity(activityId);
    }
  }, []);

  //Update session storage when activity is updated, only if activity is empty (We are creating a new one)
  useEffect(() => {
    sessionStorage.setItem('formActivity', JSON.stringify(activity));
  }, [activity]);

  return (
    <ActivityContext.Provider
      value={{
        activity,
        setActivity,
        createActivity,
        getActivity,
        validateRecurrence,
        editActivity,
        removeActivity,
        createOffspring,
        imageCache,
        setImageCache,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
