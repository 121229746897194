import { Sortable } from '@/utils/sorting';
import { toRequestBody } from './../runtime';

export type Board = Sortable & {
  id: number;
  title: string;
  notes: Note[];
  color: string;
};

export type Note = Sortable & {
  id: number;
  noteBoardId: number;
  title?: string;
  text: string;
  color: string;
};

export const toNote = (json: any): Note => {
  return {
    id: json.noteId ?? json.id,
    noteBoardId: json.noteBoardId,
    title: json.title,
    text: json.text,
    color: json.color,
    position: json.position,
  };
};

export const toBoard = (json: any): Board => {
  return {
    id: json.noteBoardId ?? json.id,
    title: json.title,
    notes: json.notes.map(toNote),
    color: json.color,
    position: json.position,
  };
};

export const noteRequestBody = (note: Note) => {
  return {
    noteId: note.id,
    noteBoardId: note.noteBoardId,
    title: note.title,
    text: note.text,
    color: note.color,
    position: note.position,
  };
};

export const boardRequestBody = (board: Board) => {
  return toRequestBody({
    noteBoardId: board.id,
    title: board.title,
    color: board.color,
    notes: board.notes.map(noteRequestBody),
    position: board.position,
  });
};
