import { Button, Card, Typography, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import DottedTimer from '@/components/utils/DottedTimer';
import ClearIcon from '@mui/icons-material/Clear';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'; //toDo move to theme
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomDurationDialog from './CustomDurationDialog';

const colors = {
  grey: '#78828A',
  green: '#1F8900',
  blue: '#387EE8',
  purple: '#7338BE',
  red: '#D1435B',
};

const DurationForm = ({ validateForm = true }: { validateForm?: boolean }) => {
  const formHandler = useContext(ActivityFormContext);
  const { activity, setActivity } = useContext(ActivityContext);
  const { t } = useTranslation();

  // const [error, setError] = useState<boolean | string>(false); // ToDo error handling for custom duration
  const [customDurationModal, setCustomDurationModal] = useState<boolean>(false);
  const validate = (): boolean => {
    return true;
  };

  const theme = useTheme();

  useEffect(() => {
    if (activity.duration !== -1 && validate()) validateForm && formHandler.setValid(true);
    else if (formHandler.current.isValid) validateForm && formHandler.setValid(false);
  }, [activity]);

  const isSelected = (durationId: number | 'CustomTime') => {
    if (durationId === 'CustomTime') return ![-1, 0, 15, 30, 45, 60].includes(activity.duration);
    return activity.duration === durationId;
  };

  const getTimeFormat = () => {
    if (activity.duration > 59) {
      const hours = Math.floor(activity.duration / 60);
      const minutes = activity.duration % 60;
      let hourText;
      if (hours == 1) hourText = t('hour');
      else hourText = t('hours');

      if (minutes == 0) return `${hours} ${hourText}`;

      return `${hours} ${hourText} ${minutes} ${t('minutes')}`;
    }

    return `${activity.duration} ${t('min')}`;
  };

  const durationOptions = [
    { time: 15, label: '15 min', iconColor: colors.green },
    { time: 30, label: '30 min', iconColor: colors.purple },
    { time: 45, label: '45 min', iconColor: colors.blue },
    { time: 60, label: '60 min', iconColor: colors.red },
    { time: 0, label: t('Specify no time'), iconColor: colors.grey },
  ];

  return (
    <>
      <Typography variant="subtitle1" textAlign={'center'}>
        {`${t('How much time will it take')}?`}
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent={'center'}
      >
        {durationOptions.map((option) => (
          <Grid
            key={option.time}
            item
            xs={6}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              minWidth: '8em',
            })}
          >
            <Button
              sx={(theme) => ({
                m: 0.5,
                flex: 1,
                maxWidth: '20em',
              })}
              onClick={() => setActivity({ ...activity, duration: option.time })}
            >
              <Card
                sx={{
                  width: '100%',
                  outline: isSelected(option.time) ? `3px solid ${theme.palette.primary.main}` : '',
                  position: 'relative',
                }}
              >
                <CheckCircleIcon
                  sx={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    color: theme.palette.primary.main,
                    visibility: isSelected(option.time) ? 'visible' : 'hidden',
                  }}
                />
                {option.time > 0 ? (
                  <DottedTimer
                    percentage={(option.time / 60) * 100}
                    color={option.iconColor}
                    style={{ width: '50%', margin: 'auto' }}
                  />
                ) : (
                  <ClearIcon sx={{ width: '50%', height: 'unset', color: 'grey' }} />
                )}

                <Typography variant="subtitle1" textAlign={'center'} textTransform={'none'}>
                  {option.label}
                </Typography>
              </Card>
            </Button>
          </Grid>
        ))}
        <Grid
          item
          xs={6}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            minWidth: '8em',
          })}
        >
          <Button
            sx={(theme) => ({
              m: 0.5,
              flex: 1,
              maxWidth: '20em',
            })}
            onClick={() => setCustomDurationModal(true)}
          >
            <Card
              sx={{
                width: '100%',
                outline: isSelected('CustomTime') ? `3px solid ${theme.palette.primary.main}` : '',
                position: 'relative',
              }}
            >
              <CheckCircleIcon
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  color: theme.palette.primary.main,
                  visibility: isSelected('CustomTime') ? 'visible' : 'hidden',
                }}
              />
              <SettingsRoundedIcon sx={{ width: '50%', height: 'unset', color: 'grey' }} />
              <Typography variant="subtitle1" textAlign={'center'} textTransform={'none'}>
                {isSelected('CustomTime') ? getTimeFormat() : t('Set my own time')}
              </Typography>
            </Card>
          </Button>
        </Grid>
        <CustomDurationDialog
          open={customDurationModal}
          onChange={(newVal) => setActivity({ ...activity, duration: newVal })}
          onClose={() => setCustomDurationModal(false)}
        />
      </Grid>
    </>
  );
};

export default DurationForm;
