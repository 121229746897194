import { Button, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsAccordion from 'src/components/settings/SettingsAccordion';
import { useContext, useState } from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { QrCode2 } from '@mui/icons-material';
import QrCodeDialog from '@/components/settings/QrCodeDialog';
import { formatClientOrSupervisor } from '@/utils/supervisor';

const SettingsPage = () => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [qrCodeDialog, setQrCodeDialog] = useState<boolean>(false);

  const handleTagClick = () => {
    setQrCodeDialog(true);
  };

  const handleCloseDialog = () => {
    setQrCodeDialog(false);
  };

  return (
    <Container>
      <Stack spacing={3} pb={3}>
        <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
          {t('Settings')}
        </Typography>
        <Stack direction="row" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleTagClick}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              {`${user?.firstName} (${user?.tag})`}
            </Typography>
            <QrCode2 />
          </Button>
          <QrCodeDialog open={qrCodeDialog} onClose={handleCloseDialog} />
        </Stack>
        <SettingsAccordion />
      </Stack>
    </Container>
  );
};

export default SettingsPage;
