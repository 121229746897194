import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

type CameraType = 'front' | 'back' | 'unknown';

type QrScannerProps = {
  onScan: (result: string) => void;
  onError?: (error: any) => void;
};

const QRScanner = ({ onScan, onError }: QrScannerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>(null);
  const scannerRef = useRef<QrScanner | null>(null);

  const [cameras, setCameras] = useState<
    {
      id: string;
      label: string;
      type: CameraType;
    }[]
  >([]);

  const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
  const [isSwitching, setIsSwitching] = useState(false);

  const detectCameraType = (label: string): CameraType => {
    const lowerLabel = label.toLowerCase();
    if (lowerLabel.includes('front') || lowerLabel.includes('user')) return 'front';
    if (lowerLabel.includes('back') || lowerLabel.includes('environment')) return 'back';
    return 'unknown';
  };

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const cameraList = await QrScanner.listCameras(true);

        let frontCamera: QrScanner.Camera | null = null;
        let backCamera: QrScanner.Camera | null = null;
        let fallbackCamera: QrScanner.Camera | null = null;

        // eslint-disable-next-line functional/no-loop-statement
        for (const cam of cameraList) {
          const type = detectCameraType(cam.label);

          if (!frontCamera && type === 'front') frontCamera = cam;
          if (!backCamera && type === 'back') backCamera = cam;
          if (!fallbackCamera) fallbackCamera = cam;
        }

        const filteredCameras = [
          backCamera,
          frontCamera,
          !backCamera && !frontCamera ? fallbackCamera : null,
        ]
          .filter((cam): cam is QrScanner.Camera => cam !== null)
          .map((cam) => ({
            id: cam.id,
            label: cam.label,
            type: detectCameraType(cam.label),
          }));

        setCameras(filteredCameras);

        setSelectedCamera(backCamera?.id || frontCamera?.id || fallbackCamera?.id || null);
      } catch (fetchError) {
        console.error('Failed to access cameras. Please check permissions.', fetchError);
        onError?.(fetchError);
      }
    };

    fetchCameras();
  }, []);

  useEffect(() => {
    const initializeScanner = async () => {
      if (!videoRef.current || !selectedCamera) return;

      try {
        if (scannerRef.current) {
          await scannerRef.current.stop();
          await scannerRef.current.setCamera(selectedCamera);
          await scannerRef.current.start();
          return;
        }

        scannerRef.current = new QrScanner(
          videoRef.current,
          (result: QrScanner.ScanResult) => onScan(result.data),
          {
            highlightScanRegion: true,
            highlightCodeOutline: true,
          },
        );

        if (theme.palette.mode === 'dark') {
          scannerRef.current.setInversionMode('invert');
        }

        await scannerRef.current.start();
        await scannerRef.current.setCamera(selectedCamera);
      } catch (initError) {
        console.error('Scanner initialization failed.', initError);
        onError?.(initError);
      }
    };

    initializeScanner();

    return () => {
      scannerRef.current?.stop();
    };
  }, [selectedCamera, onScan, onError, theme.palette.mode]);

  const switchCamera = async () => {
    if (cameras.length < 2 || isSwitching) return;

    setIsSwitching(true);

    try {
      const currentIndex = cameras.findIndex((cam) => cam.id === selectedCamera);
      const nextIndex = (currentIndex + 1) % cameras.length;
      const newCameraId = cameras[nextIndex].id;

      setSelectedCamera(newCameraId);

      if (scannerRef.current) {
        await scannerRef.current.stop();
        await scannerRef.current.setCamera(newCameraId);
        await scannerRef.current.start();
      }
    } catch (switchError) {
      console.error('Failed to switch camera.', switchError);
      onError?.(switchError);
    } finally {
      setTimeout(() => setIsSwitching(false), 500);
    }
  };

  return (
    <Stack spacing={2} alignItems="center">
      <Box position="relative" width="34vh" height="34vh">
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Stack>
  );
};

export default QRScanner;
