import { Location } from '@/api/types/Location';
import { LocationContext } from '@/contexts/LocationContext';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  LinearProgress,
  Stack,
  useTheme,
  Dialog,
  IconButton,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, Room } from '@mui/icons-material';
import ColorSelector from '@/components/notes/colorSelect/ColorSelector';
import { ResponseError } from '@api';
import DeleteLocation from './DeleteLocation';
import LocationInput from './LocationInput';
import { openGoogleMaps } from '@/utils/location';

type ManageLocationDialogProps = {
  location?: Location;
  open: boolean;
  onClose: () => void;
};

type ValidLocation = {
  valid: boolean;
  message?: string;
};

const defaultLocation: Location = {
  locationId: 0,
  locationName: '',
  locationAddress: '',
  color: '#53C433',
};

const ManageLocationsDialog = ({ location, open, onClose }: ManageLocationDialogProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { createLocation, editLocation } = useContext(LocationContext);

  const [formLocation, setFormLocation] = useState<Location>(location ?? defaultLocation);
  const [validLocation, setValidLocation] = useState<ValidLocation>({ valid: false });
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<boolean | string>(false);

  useEffect(() => {
    if (!open) {
      setFormLocation(defaultLocation);
      setError(false);
    } else {
      setFormLocation(location ?? defaultLocation);
    }
  }, [open]);

  const onSave = async () => {
    setSending(true);
    let create;
    if (location) {
      create = await editLocation(formLocation);
    } else {
      create = await createLocation(formLocation);
    }

    if (create && create instanceof ResponseError) {
      setError(true);
      setSending(false);

      return;
    }

    setSending(false);
    setFormLocation(formLocation);
    onClose();
  };

  useEffect(() => {
    const validLocation =
      ((formLocation.locationName?.length ?? 0) !== 0 ||
        (formLocation.locationAddress?.length ?? 0) !== 0) &&
      (formLocation.color ?? '').match(/^#[0-9A-F]{6}$/i) !== null;

    if (
      location &&
      location.locationName === formLocation.locationName &&
      location.locationAddress === formLocation.locationAddress &&
      location.color === formLocation.color
    ) {
      setValidLocation({ valid: false, message: 'No changes made' });
      return;
    }

    if (!validLocation) {
      if (
        (formLocation.locationName?.length ?? 0) === 0 &&
        (formLocation.locationAddress?.length ?? 0) === 0
      ) {
        setValidLocation({
          valid: false,
          message: 'Location address and name cannot be empty',
        });
      } else {
        setValidLocation({ valid: false, message: 'Invalid location' });
      }
      return;
    }

    setValidLocation({ valid: true });
  }, [formLocation]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-label="manage-location-dialog-title"
      aria-describedby="manage-location-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: palette.background.paper,
          px: 2,
        }}
      >
        {location && <DeleteLocation location={location} onClose={onClose} />}
        <DialogTitle sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Input
            value={formLocation.locationName}
            onChange={(e) => setFormLocation({ ...formLocation, locationName: e.target.value })}
            placeholder={t('Location name') ?? ''}
            inputProps={{ maxLength: 64 }}
            sx={{
              '& .MuiInputBase-input': {
                padding: 0,
                fontSize: '1.25rem',
                textAlign: 'center',
                color: palette.black,
              },
              ':before': {
                borderBottomColor: palette.black,
              },
              ':after': {
                borderBottomColor: palette.black,
              },
            }}
          />
        </DialogTitle>
        <Close
          onClick={onClose}
          sx={{
            cursor: 'pointer',
          }}
        ></Close>
      </Box>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mb: 3, px: 1 }}>
          <LocationInput
            value={
              location?.locationAddress
                ? location.locationAddress
                : formLocation.locationAddress || ''
            }
            onChange={(newAddress) => {
              setFormLocation({ ...formLocation, locationAddress: newAddress });
            }}
            error={error}
            setError={setError}
            isManagingLocations={true}
          />
        </Box>

        {error && (
          <Box
            sx={{
              color: error ? 'red' : 'orange',
              fontSize: '1.25rem',
              fontWeight: 'bold',
              padding: '5px',
              marginBottom: '10px',
            }}
          >
            {error && t('Something went wrong')}
          </Box>
        )}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '10fr 1fr',
            '@media (max-width: 384px)': {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          <ColorSelector
            selectedColor={formLocation.color}
            onSelectColor={(newColor) => setFormLocation({ ...formLocation, color: newColor })}
          ></ColorSelector>
          <DialogActions sx={{ justifyContent: 'unset' }}>
            <Button
              onClick={onSave}
              variant="contained"
              color="primary"
              disabled={!!error || !validLocation.valid || sending}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      {sending && (
        <Stack
          justifyContent={'center'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <LinearProgress color="info" />
        </Stack>
      )}
    </Dialog>
  );
};

export default ManageLocationsDialog;
