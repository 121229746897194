/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { HttpStatusCode } from 'axios';
import { BaseAPI } from '../baseApi';
import { Location } from '../types/Location';

export interface CreateLocationRequest {
  location: Location;
}
export interface GetLocationRequest {
  locationId: number;
}
export interface UpdateLocationRequest {
  location: Location;
}
export interface RemoveLocationRequest {
  locationId: number;
}

export class LocationApi extends BaseAPI {
  async createLocation(
    requestParameters: CreateLocationRequest,
  ): Promise<Location | runtime.ResponseError | undefined> {
    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    try {
      const response = await this.request({
        path: `/locations`,
        method: 'POST',
        headers: headerParameters,
        body: requestParameters.location,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Location => jsonValue,
      ).value();
    } catch (error) {
      console.error('Error creating Location:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  }

  async getAllLocations(): Promise<Array<Location> | runtime.ResponseError | undefined> {
    try {
      const response = await this.request({
        path: `/locations`,
        method: 'GET',
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<Location> => jsonValue,
      ).value();
    } catch (error) {
      console.error('Error fetching Locations:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  }

  async getLocation(
    requestParameters: GetLocationRequest,
  ): Promise<Location | runtime.ResponseError | undefined> {
    try {
      const uri = encodeURIComponent(requestParameters.locationId);

      const response = await this.request({
        path: `/locations/${uri}`,
        method: 'GET',
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Location => jsonValue,
      ).value();
    } catch (error) {
      console.error('Error fetching Location:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  }

  async updateLocation(
    requestParameters: UpdateLocationRequest,
  ): Promise<HttpStatusCode | runtime.ResponseError | undefined> {
    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    try {
      const response = await this.request({
        path: `/locations`,
        method: 'PUT',
        headers: headerParameters,
        body: requestParameters.location,
      });

      return response.status;
    } catch (error) {
      console.error('Error updating Location:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  }

  async removeLocation(
    requestParameters: RemoveLocationRequest,
  ): Promise<HttpStatusCode | runtime.ResponseError | undefined> {
    try {
      const uri = encodeURIComponent(requestParameters.locationId);

      const response = await this.request({
        path: `/locations/${uri}`,
        method: 'DELETE',
      });

      return response.status;
    } catch (error) {
      console.error('Error removing Location:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  }
}
