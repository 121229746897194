import { useContext } from 'react';
import { SupervisorContext } from '@/contexts/SupervisorContext';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatClientOrSupervisor } from '@/utils/supervisor';

const SupervisorHeaderText = ({ headerText = 'Supervising' }: { headerText?: string }) => {
  const { currentClient } = useContext(SupervisorContext);
  const { t } = useTranslation();

  return (
    <Stack direction="column" alignItems="center">
      <Typography variant="h6" component="h1">
        {t(headerText)}
      </Typography>
      <Typography variant="body1" component="h2">
        {formatClientOrSupervisor(currentClient)}
      </Typography>
    </Stack>
  );
};

export default SupervisorHeaderText;
