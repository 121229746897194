import { svSE, enUS, deDE, frFR, esES, itIT, plPL, fiFI, nbNO } from '@mui/x-date-pickers';
import { PickersInputLocaleText } from '@mui/x-date-pickers/locales';
import { daDK } from '@mui/x-date-pickers/locales/daDK';

// Translations for text in datepicker
export const muiLocales: Record<string, PickersInputLocaleText<Date>> = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  sv: svSE.components.MuiLocalizationProvider.defaultProps.localeText,
  de: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
  it: itIT.components.MuiLocalizationProvider.defaultProps.localeText,
  pl: plPL.components.MuiLocalizationProvider.defaultProps.localeText,
  da: daDK.components.MuiLocalizationProvider.defaultProps.localeText,
  nb: nbNO.components.MuiLocalizationProvider.defaultProps.localeText,
  fi: fiFI.components.MuiLocalizationProvider.defaultProps.localeText,
};
