/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useRef, useContext } from 'react';
import { Box, Stack } from '@mui/material';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import NoteItem from './NoteItem';
import SortableNoteItem from './SortableNoteItem';
import { Board, Note } from '@api';
import { createPortal } from 'react-dom';
import { NotesContext } from 'src/contexts/NotesContext';
import { deleteItemAnimation } from './DeleteItemAnimation';
import DeleteArea from './DeleteArea';
import NoteDialog from './NoteDialog';
import AddNoteButton from './AddNoteButton';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { isMobile } from 'react-device-detect';
import { reorderSortableList } from '@/utils/sorting';

const defaultNoteConfig = {
  cardWidth: 150,
  cardMargin: 4,
  cardDragDelay: 300,
  itemsPerRow: 4,
};

const BoardComponent = ({ board }: { board: Board }) => {
  const [notes, setNotes] = useState<Note[]>([]);

  const [activeNote, setActiveNote] = useState<Note | null>(null);

  const [cardConfig, setCardConfig] = useState(defaultNoteConfig);

  const maxNrOfNotes = 8;

  let sensors;

  if (isMobile) {
    sensors = useSensors(
      useSensor(TouchSensor, {
        activationConstraint: {
          delay: cardConfig.cardDragDelay, // ms until activation
          tolerance: 10,
        },
      }),
    );
  } else {
    sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor, {
        activationConstraint: {
          delay: cardConfig.cardDragDelay, // ms until activation
          tolerance: 10,
        },
      }),
    );
  }

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [shouldUpdateDB, setShouldUpdateDB] = useState(false);

  const { updateNoteOrder, deleteNote } = useContext(NotesContext);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [noteDialog, setNoteDialog] = useState<Note | boolean>(false);

  const swiperSlide = useSwiperSlide();
  const swiper = useSwiper();
  swiper.updateAutoHeight();

  const updateNotes = () => {
    const notes = board?.notes;
    setNotes(notes ? [...notes] : []);
  };

  useEffect(() => {
    updateNotes();
  }, [board.notes]);

  useEffect(() => {
    if (shouldUpdateDB) {
      updateNoteOrder(notes, board.id);
      setShouldUpdateDB(false);
    }
  }, [shouldUpdateDB]);

  useEffect(() => {
    const calculateNumberOfGhosts = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const marginPercentage = 1;
        const maxNrOfCardsPerRow = 4;

        const calculatedCardWidth = containerWidth / (maxNrOfCardsPerRow + 1);
        const calculatedCardMargin = containerWidth * (marginPercentage / 100);

        const cardWidth = Math.max(defaultNoteConfig.cardWidth, calculatedCardWidth);
        const cardMargin = Math.max(defaultNoteConfig.cardMargin, calculatedCardMargin);

        const noteWidth = cardWidth + cardMargin * 2;
        const itemsPerRow = Math.floor(containerWidth / noteWidth);

        setCardConfig({
          cardWidth: cardWidth,
          cardMargin: cardMargin,
          cardDragDelay: 300,
          itemsPerRow: itemsPerRow,
        });
      }
    };
    calculateNumberOfGhosts();
    // Recalculate the number of ghosts when the window is resized
    window.addEventListener('resize', calculateNumberOfGhosts);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('resize', calculateNumberOfGhosts);
    };
  }, [containerRef, notes]);

  const handleMouseMove = (event: PointerEvent) => {
    const screenHeight = window.innerHeight;
    const pointerY = event.clientY;
    const bottomThreshold = screenHeight * 0.9;

    // Check if the pointer is in the bottom 10% of the screen
    if (pointerY >= bottomThreshold) {
      // The pointer is in the bottom 10% of the screen
      setDeleteItem(true);
    } else {
      setDeleteItem(false);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    document.body.addEventListener('pointermove', handleMouseMove);
    const activeNote = notes.find((note) => note.id === event.active.id);
    if (activeNote) {
      setActiveNote(activeNote);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    document.body.removeEventListener('pointermove', handleMouseMove);
    const { active, over } = event;

    if (deleteItem) {
      // Delete the note
      const newNotes = notes.filter((note) => note.id !== active?.id);
      setNotes(newNotes);
      deleteNote(active.id as number, board.id);
      setDeleteItem(false);
      //TODO Delete from db
    } else if (active && over && active.id !== over?.id) {
      setNotes((items) => {
        return reorderSortableList(items, active, over, 'id');
      });
      //TODO Update order in db
      setShouldUpdateDB(true);
    }

    /*  
        For the stop-drag event, we can call it here instead of a prop, 
        as the timing is not important.
    */

    if (deleteItem) {
      deleteItemAnimation();
    }
    setActiveNote(null);
  };

  const handleDragCancel = () => {
    setActiveNote(null);
  };

  return (
    <>
      <Box sx={{ width: '100%', mt: 1, height: '100%' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={notes} strategy={rectSortingStrategy}>
            <Stack
              ref={containerRef}
              direction="row"
              flexWrap="wrap"
              justifyContent="start"
              px={0.5}
            >
              {notes.map((note) => (
                <Stack
                  key={note.id}
                  justifyContent={'center'}
                  width={`${100 / cardConfig.itemsPerRow}%`}
                  minWidth={cardConfig.cardWidth + cardConfig.cardMargin * 2}
                  direction={'row'}
                >
                  <SortableNoteItem
                    note={note}
                    setNoteDialog={setNoteDialog}
                    cardDragDelay={cardConfig.cardDragDelay}
                    cardWidth={cardConfig.cardWidth}
                    cardMargin={cardConfig.cardMargin}
                  />
                </Stack>
              ))}
            </Stack>
          </SortableContext>
          {createPortal(
            <>
              <DragOverlay adjustScale>
                {activeNote ? (
                  <NoteItem
                    id="dragOverlayElement"
                    note={activeNote}
                    isDragging
                    deleteItem={deleteItem}
                    cardDragDelay={cardConfig.cardDragDelay}
                    cardWidth={cardConfig.cardWidth}
                    cardMargin={cardConfig.cardMargin}
                  />
                ) : null}
              </DragOverlay>
            </>,
            document.body,
          )}
        </DndContext>
      </Box>
      <DeleteArea isDeleting={deleteItem} visible={!!activeNote} />
      {noteDialog && (
        <NoteDialog
          open={!!noteDialog}
          onClose={() => {
            setNoteDialog(false);
            updateNotes();
          }}
          noteBoardId={board.id}
          note={typeof noteDialog === 'boolean' ? undefined : noteDialog}
          notes={notes}
        />
      )}
      {swiperSlide.isActive && (
        <AddNoteButton
          onClick={() => setNoteDialog(true)}
          disabled={notes.length >= maxNrOfNotes}
        />
      )}
    </>
  );
};

export default BoardComponent;
