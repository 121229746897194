import { Typography, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AIProfileContext } from '@/contexts/AIProfileContext';
import { useTranslation } from 'react-i18next';
import { AiApi } from '@api';

const CompletedForm = () => {
  const { saveAIProfile } = useContext(AIProfileContext);
  const { t } = useTranslation();

  useEffect(() => {
    saveAIProfile();
    new AiApi().clearSuggestionCache();
  }, []);

  return (
    <Stack spacing={3}>
      <Typography>{t('Thank you for filling in your AI-profile.')}</Typography>
      <Typography>{t('You can change your data at any time')}</Typography>
    </Stack>
  );
};

export default CompletedForm;
