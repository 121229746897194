import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ColorCircle from './ColorCircle';

// Color copied from from theme.activities.
export const defaultColors = ['#53C433', '#FF6A7E', '#65A6FF', '#FFE749'];

type ColorSelectorProps = {
  defaultColor?: string;
  selectedColor?: string;
  onSelectColor?: (color: string) => void;
  overrideColors?: string[];
};

const ColorSelector = ({
  defaultColor,
  selectedColor,
  onSelectColor,
  overrideColors,
}: ColorSelectorProps) => {
  const [internalColor, setInternalColor] = useState<string | null>(defaultColor ?? null);

  useEffect(() => {
    if (selectedColor) {
      setInternalColor(selectedColor);
    } else if (internalColor) setInternalColor(null);
  }, [selectedColor]);

  const handleColorClick = (color: string) => {
    onSelectColor?.(color);
    if (!selectedColor) setInternalColor(color);
  };

  const colors = overrideColors ?? defaultColors;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexShrink: 0,
      }}
    >
      {colors.map((color) => (
        <ColorCircle
          key={color}
          color={color}
          isSelected={
            color.toLocaleUpperCase() ===
            (selectedColor?.toLocaleUpperCase() || internalColor?.toLocaleUpperCase())
          }
          onClick={() => handleColorClick(color)}
        />
      ))}
    </Box>
  );
};

export default ColorSelector;
