import { Note, ResponseError } from '@api';
import { Close, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  LinearProgress,
  Stack,
  TextField,
  Theme,
  lighten,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ColorSelector from './colorSelect/ColorSelector';
import { useTranslation } from 'react-i18next';
import { NotesContext } from 'src/contexts/NotesContext';
import DeleteNote from './DeleteNote';

type NoteFormProps = {
  note?: Note;
  onClose: () => void;
  noteBoardId: number;
  notes: Note[];
};

const defaultNote: Note = {
  id: 1,
  title: '',
  text: '',
  color: '#53C433',
  position: 1,
  noteBoardId: 0,
};

const NoteForm = ({ note, onClose, noteBoardId, notes }: NoteFormProps) => {
  const { t } = useTranslation();
  const { createNote, editNote } = useContext(NotesContext);

  const [formNote, setFormNote] = useState<Note>(note ?? defaultNote);
  const [validBoard, setValidBoard] = useState(false);
  const [sending, setSending] = useState(false);

  const [errorMsg, setErrorMsg] = useState(false);

  const onSave = async () => {
    setSending(true);
    let create;
    if (note) {
      create = await editNote({
        ...formNote,
        noteBoardId: noteBoardId,
      });
    } else {
      const newPosition = notes.length;

      create = await createNote({
        ...formNote,
        noteBoardId: noteBoardId,
        position: newPosition ? newPosition + 1 : 1,
      });
    }

    if (create && create instanceof ResponseError) {
      setErrorMsg(true);
      setSending(false);

      return;
    }

    setSending(false);
    onClose();
  };

  useEffect(() => {
    const validBoard =
      formNote.text.length !== 0 && formNote.color.match(/^#[0-9A-F]{6}$/i) !== null;

    // Check if is the same note
    if (
      note &&
      note.title === formNote.title &&
      note.text === formNote.text &&
      note.color === formNote.color
    ) {
      setValidBoard(false);
      return;
    }
    setValidBoard(validBoard);
  }, [formNote]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: formNote.color,
          px: 2,
        }}
      >
        {note && <DeleteNote note={note} noteBoardId={noteBoardId} onClose={onClose} />}
        <DialogTitle sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Input
            value={formNote.title}
            onChange={(e) => setFormNote({ ...formNote, title: e.target.value })}
            placeholder={t('Title') ?? ''}
            inputProps={{ maxLength: 64 }} //should match DB
            sx={{
              '& .MuiInputBase-input': {
                padding: 0,
                fontSize: '1.25rem',
                textAlign: 'center',
                color: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              ':before': {
                borderBottomColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              ':after': {
                borderBottomColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
            }}
          />
        </DialogTitle>
        <Close
          onClick={onClose}
          sx={{
            cursor: 'pointer',
          }}
        ></Close>
      </Box>
      <DialogContent
        sx={{
          background: lighten(formNote.color, 0.7),
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          color={(theme: Theme) => theme.palette.black}
          sx={{ minHeight: '10em', pt: 2 }}
          overflow={'auto'}
        >
          <TextField
            value={formNote.text}
            onChange={(e) => setFormNote({ ...formNote, text: e.target.value })}
            multiline
            fullWidth
            placeholder={t('Note Text') ?? ''}
            inputProps={{ maxLength: 256 }} //should match DB
            sx={{
              '& .MuiInputBase-input': {
                padding: 0,
                color: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              backgroundColor: lighten(formNote.color, 0.75),
              borderRadius: '5px',
            }}
          />
        </Box>

        {errorMsg ? (
          <Box
            sx={{
              color: 'red',
              fontSize: '1.25rem',
              fontWeight: 'bold',
              padding: '5px',
              marginBottom: '10px',
              backgroundColor: lighten(formNote.color, 0.95),
            }}
          >
            {t('Something went wrong!')}
          </Box>
        ) : null}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '10fr 1fr',
            '@media (max-width: 384px)': {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          <ColorSelector
            selectedColor={formNote.color}
            onSelectColor={(newColor) => setFormNote({ ...formNote, color: newColor })}
          ></ColorSelector>
          <DialogActions sx={{ justifyContent: 'unset' }}>
            <Button
              onClick={onSave}
              variant="contained"
              color="primary"
              disabled={!validBoard || sending}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      {sending && (
        <Stack
          justifyContent={'center'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <LinearProgress color="info" />
        </Stack>
      )}
    </>
  );
};

export default NoteForm;
