import { Board } from '@api';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import ConfirmDialog from '@/components/utils/ConfirmDialog';
import { NotesContext } from 'src/contexts/NotesContext';
import { Button } from '@mui/material';

type DeleteNoteProps = {
  board: Board;
  onClose: () => void;
};

const DeleteNoteBoard = ({ board, onClose }: DeleteNoteProps) => {
  const [sending, setSending] = useState(false);
  const { deleteBoard } = useContext(NotesContext);

  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const onRemove = async () => {
    setSending(true);
    if (board) {
      await deleteBoard(board);
    }
    onClose();
    setSending(false);
  };

  return (
    <>
      <Button
        variant={'contained'}
        onClick={() => setConfirmOpen(true)}
        color={'error'}
        disabled={sending}
      >
        {t('Remove')}
      </Button>
      <ConfirmDialog
        bodyText={t('Are you sure you want to remove this note board?')}
        onClose={closeConfirmDialog}
        onConfirm={onRemove}
        title={t('Remove note board')}
        open={confirmOpen}
        yesNo
      />
    </>
  );
};

export default DeleteNoteBoard;
