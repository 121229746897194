import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { SubtaskItem } from '@api';
import { AIProfile } from '@/contexts/AIProfileContext';
import { HttpStatusCode } from 'axios';
import { intervalType } from '@/contexts/StatisticsContext';
/* tslint:disable */
/* eslint-disable */

export type Suggestion = {
  activityName: string;
  startDate: string;
  duration: number;
  description: string;
  subtasks?: SubtaskItem[];
};

export type languageKey = 'sv' | 'en' | 'de' | 'fr' | 'es' | 'it' | 'pl' | 'nb' | 'da' | 'fi';

const LanguageMapper: Record<languageKey, string> = {
  sv: 'Svenska',
  en: 'Engelska',
  de: 'Tyska',
  fr: 'Franska',
  es: 'Spanska',
  it: 'Italienska',
  pl: 'Polska',
  nb: 'Norska',
  da: 'Danska',
  fi: 'Finska',
};

export class AiApi extends BaseAPI {
  // Rensa cachen
  clearSuggestionCache = () => {
    localStorage.removeItem('suggestionCache');
    localStorage.removeItem('suggestionCache_exp');
    localStorage.removeItem('suggestionCache_language');
  };

  // Spara data i cachen
  saveSuggestionCache = (data: string, language: languageKey) => {
    const suggestionCacheTimeLimit = 60 * 60 * 1000;
    localStorage.setItem('suggestionCache', JSON.stringify(data));
    localStorage.setItem('suggestionCache_exp', (Date.now() + suggestionCacheTimeLimit).toString());
    localStorage.setItem('suggestionCache_language', language);
  };

  getSuggestionCache = (language: languageKey) => {
    const cacheLanguage = localStorage.getItem('suggestionCache_language');
    const expiration = localStorage.getItem('suggestionCache_exp');
    if (expiration && Date.now() < parseInt(expiration, 10) && cacheLanguage === language) {
      const data = localStorage.getItem('suggestionCache');
      return data ? JSON.parse(data) : null;
    }
    // Rensa cache om den är för gammal
    this.clearSuggestionCache();
    return null;
  };

  activitySuggestions = async (
    language: languageKey,
    suggestionSpecification?: string,
    refresh = false,
  ): Promise<Suggestion[]> => {
    const cachedData = this.getSuggestionCache(language);

    const oldActivities: Suggestion[] = [];

    if (cachedData) {
      try {
        const parsedCache: Suggestion[] = JSON.parse(cachedData);
        if (Array.isArray(parsedCache) && parsedCache.length === 3) {
          if (!refresh) return parsedCache;
          else oldActivities.push(...parsedCache);
        } else this.clearSuggestionCache();
      } catch (e) {
        console.error('Error parsing cached data:', e);
        // There might be something wrong with the cache, clear it
        this.clearSuggestionCache();
      }
    }

    const previousSuggestions = JSON.stringify(oldActivities);

    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      const queryString = suggestionSpecification ? `/${suggestionSpecification}` : '';
      const response = await this.request({
        path: `/ai/suggestions/${LanguageMapper[language] + queryString}`,
        method: 'POST',
        headers: headerParameters,
        body: previousSuggestions,
      });

      const responseData = response.data.choices[0].message.content ?? '';
      this.saveSuggestionCache(responseData, language);

      const parsedResponse = JSON.parse(responseData);
      if (Array.isArray(parsedResponse) && parsedResponse.length === 3) {
        return parsedResponse;
      } else {
        console.error('Invalid response from AI:', responseData);
        return [];
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      return [];
    }
  };

  statisticsAnalysis = async (interval?: intervalType, language?: languageKey): Promise<string> => {
    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await this.request({
        path: `/ai/statistics/${interval}/${LanguageMapper[language ?? 'sv']}`,
        method: 'GET',
        headers: headerParameters,
      });

      return response.data.choices[0].message.content ?? '';
    } catch (error) {
      console.error('Error fetching statistics:', error);
      return 'Could not analyze statistics';
    }
  };

  generateImage = async (languge: languageKey, description: string): Promise<File | null> => {
    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await this.request({
        path: `/ai/image/${LanguageMapper[languge]}`,
        method: 'POST',
        headers: headerParameters,
        body: JSON.stringify({ description }),
      });

      const base64Data = response.data; // Assuming `response.data` is base64

      // Convert base64 string to Blob directly
      const blob = await (await fetch(`data:image/png;base64,${base64Data}`)).blob();

      // Create a File from the Blob
      const file = new File([blob], 'generated-image.png', { type: 'image/png' });

      return file;
    } catch (error) {
      console.error('Error when generating image:', error);
      // Handle or throw the error as needed
      throw error;
    }
  };

  createAIProfile = async (
    aiProfile: AIProfile,
  ): Promise<AIProfile | runtime.ResponseError | undefined> => {
    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await this.request({
        path: '/ai/profile',
        method: 'POST',
        headers: headerParameters,
        body: JSON.stringify(aiProfile),
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): AIProfile => jsonValue,
      ).value();
    } catch (error) {
      console.error('Error creating AI profile:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  };

  getAIProfile = async (): Promise<AIProfile | undefined> => {
    try {
      const response = await this.request({
        path: '/ai/profile',
        method: 'GET',
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): AIProfile => jsonValue,
      ).value();
    } catch (error) {
      console.error('Error getting AI profile:', error);
    }
  };

  updateAIProfile = async (
    aiProfile: AIProfile,
  ): Promise<HttpStatusCode | runtime.ResponseError | undefined> => {
    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await this.request({
        path: '/ai/profile',
        method: 'PUT',
        headers: headerParameters,
        body: JSON.stringify(aiProfile),
      });

      return response.status;
    } catch (error) {
      console.error('Error updating AI profile:', error);
      if (error instanceof runtime.ResponseError) {
        return error;
      }
    }
  };

  deleteAIProfile = async (): Promise<HttpStatusCode | undefined> => {
    try {
      const response = await this.request({
        path: '/ai/profile',
        method: 'DELETE',
      });
      return response.status;
    } catch (error) {
      console.error('Error deleting AI profile:', error);
    }
  };
}
