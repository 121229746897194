import { Box, useTheme } from '@mui/material';

const CurrentBoardIndicator = ({
  isSelected,
  lastItemSelected,
  onClick,
  addButton,
  hideAddButton,
}: {
  isSelected: boolean;
  lastItemSelected: boolean;
  onClick: () => void;
  addButton?: boolean;
  hideAddButton?: boolean;
}) => {
  const theme = useTheme();

  const getDotColor = (dotId?: number) => {
    const isDark = theme.palette.mode === 'dark';
    if (lastItemSelected) {
      return dotId ? theme.palette.common.white : theme.palette.primary.main;
    }
    return isSelected
      ? theme.palette.common.black
      : isDark
      ? theme.palette.common.white
      : theme.palette.line;
  };

  const getDotBorder = () => {
    if (lastItemSelected) {
      return `1.5px solid ${theme.palette.primary.main}`;
    }
    return `1.5px solid ${theme.palette.lineDark}`;
  };

  const getHoverColor = () => {
    return `${theme.palette.action.hover}`;
  };

  if (addButton) {
    if (hideAddButton) return <></>;
    else
      return (
        <Box
          onClick={onClick}
          mx={0.5}
          fontSize={'1.25em'}
          color={getDotColor()}
          fontWeight={'bold'}
          paddingBottom={'1px'}
          sx={{
            textShadow: !isSelected
              ? `-1px -1px 0 ${theme.palette.lineDark}, 1px -1px 0 ${theme.palette.lineDark}, -1px 1px 0 ${theme.palette.lineDark}, 1px 1px 0 ${theme.palette.lineDark}`
              : 'none',
            '&:hover': {
              cursor: 'pointer',
              color: getHoverColor(),
            },
          }}
        >
          +
        </Box>
      );
  }

  return (
    <Box
      onClick={onClick}
      bgcolor={getDotColor()}
      border={getDotBorder()}
      borderRadius="50%"
      width={12}
      height={12}
      mx={0.5}
      sx={{
        '&:hover': {
          bgcolor: getHoverColor(),
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default CurrentBoardIndicator;
