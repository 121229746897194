import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useContext, useState } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useTranslation } from 'react-i18next';

const PreviousButton = ({
  unsavedSubtasks,
  updateSubtasks,
}: {
  unsavedSubtasks?: () => Promise<boolean>;
  updateSubtasks?: () => void;
}) => {
  const { t } = useTranslation();
  const { current, goToPreviousForm } = useContext(ActivityFormContext);
  const activity = useContext(ActivityContext);
  const [open, setOpen] = useState(false);

  const handleBack = async () => {
    if (current.previousFormAction === 'ValidateRecurrence') {
      activity.validateRecurrence?.();
      goToPreviousForm();
    } else if (current.formId === 'AddSubtasks' && unsavedSubtasks) {
      if (await unsavedSubtasks()) {
        setOpen(true);
      } else {
        goToPreviousForm();
      }
    } else {
      goToPreviousForm();
    }
  };

  const handleSave = () => {
    if (updateSubtasks) updateSubtasks();
    return;
  };

  return (
    <>
      <IconButton
        aria-label={`${t('Previous week')}`}
        sx={{ position: 'absolute', padding: 1, bgcolor: 'line' }}
        onClick={handleBack}
      >
        <ArrowBackOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ whiteSpace: 'pre-line' }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Unsaved subtasks')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>{t('Save subtasks')}</Button>
          <Button onClick={goToPreviousForm}>{t("Don't save")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviousButton;
